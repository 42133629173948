import { WEBSOCKET_HEADER } from 'app/constants';
import { commonService, socketService } from 'app/services';
import store from 'app/store';
import { connectionAction } from 'app/store/authentication';
import { axiosInstance } from 'app/utils/request';
const connectFail = () =>
    commonService.messages.show({
        severity: 'error',
        // summary: t('M001'),
        // detail: t('M002'),
        sticky: true
    });
function registerSocketServices() {
    socketService.listenConnect(({ detail }) => {
        axiosInstance.defaults.headers.common[WEBSOCKET_HEADER] = detail;
        store.dispatch(connectionAction(true));
    });
    socketService.listenConnectFail(connectFail);
    socketService.listenConnectErrorOnce(connectFail);
}
export { registerSocketServices };
