import { ROUTER, UTILITIES_ROOM, UTILITY } from 'app/constants';
import { commonService, roomService } from 'app/services';
import { useRedirect } from 'app/utils/browser';
import environments from 'environments';
import _ from 'lodash';
import { Calendar } from 'primereact/calendar';
import { Galleria } from 'primereact/galleria';
import { Image } from 'primereact/image';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { RadioButton } from 'primereact/radiobutton';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { generatePath, useLocation } from 'react-router-dom';
export default function RoomDetail() {
    const location = useLocation();
    const redirect = useRedirect();
    const [selectBed, setSelectedBed] = useState(1);
    const [activeIndex, setActiveIndex] = useState(0);
    const [checkIn, setCheckIn] = useState();
    const [checkOut, setCheckOut] = useState();
    const [selectedChildren, setSelectedChildren] = useState(0);
    const [selectedAdults, setSelectedAdults] = useState(0);
    const [card, setCard] = useState(1);
    const op = useRef(null);
    const [allData, setData] = useState({});
    const [breakfast, setValueBreakfast] = useState([]);
    const [room, setRecentRoom] = useState([]);
    const [utilities, setUtilities] = useState({
        DISABILITY: [],
        BATHROOM: [],
        BEDROOM: [],
        ENTERTAINMENT: [],
        FAMILY: [],
        FOOD: [],
        INTERNET: [],
        MORE: []
    });
    const [breakfastMain, setBreakfastMain] = useState({});
    /**
     * fetch data from db with ID
     * @returns
     */
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await roomService.detail({ ID: location.state });
        setData(data);
        setBreakfastMain({ check: false, price: data.Price.Norm, priceSale: data.Price.Sale });
        commonService.preloader.hide();
        return true;
    };
    const getListUtilities = async () => {
        const data = await roomService.getListUtilities();
        const result = {
            DISABILITY: data.filter((v) => v.Type === UTILITIES_ROOM.DISABILITY),
            BATHROOM: data.filter((v) => v.Type === UTILITIES_ROOM.BATHROOM),
            BEDROOM: data.filter((v) => v.Type === UTILITIES_ROOM.BEDROOM),
            ENTERTAINMENT: data.filter((v) => v.Type === UTILITIES_ROOM.ENTERTAINMENT),
            FAMILY: data.filter((v) => v.Type === UTILITIES_ROOM.FAMILY),
            FOOD: data.filter((v) => v.Type === UTILITIES_ROOM.FOOD),
            INTERNET: data.filter((v) => v.Type === UTILITIES_ROOM.INTERNET),
            MORE: data.filter((v) => v.Type === UTILITIES_ROOM.MORE)
        };
        setUtilities(result);
    };
    const recentRoom = async () => {
        const data = await roomService.recentRoom({ ID: location.state });
        const res = data.map((v) => {
            return { _id: v.ID, check: false, price: v.Price.Norm, priceSale: v.Price.Sale };
        });
        setValueBreakfast(res);
        setRecentRoom(data);
    };
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
        getListUtilities();
        recentRoom();
    }, []);
    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 1
        }
    ];
    const itemTemplate = (item) => {
        return (
            <Image src={`${environments.PUBLIC_IMAGE}/image/room/${location.state}/${item}`} alt={item} style={{ width: '100%' }} preview />
        );
    };

    const getContentArray = (content) => {
        switch (content) {
            case UTILITY.DISABILITY:
                return utilities.DISABILITY;
            case UTILITY.BATHROOM:
                return utilities.BATHROOM;
            case UTILITY.BEDROOM:
                return utilities.BEDROOM;
            case UTILITY.ENTERTAINMENT:
                return utilities.ENTERTAINMENT;
            case UTILITY.INTERNET:
                return utilities.INTERNET;
            case UTILITY.FAMILY:
                return utilities.FAMILY;
            case UTILITY.FOOD:
                return utilities.FOOD;
            case UTILITY.MORE:
                return utilities.MORE;

            default:
                return [];
        }
    };
    /**
     * Update user permission
     * @param {String} _id
     * @param {Number} permission
     */
    const onChange = (_id, permission, price) => {
        const temp = _.keyBy(breakfast, '_id');
        // update data in current table
        temp[_id].check = permission;
        if (!_.isEmpty(price)) {
            temp[_id].price = price.Norm;
            temp[_id].priceSale = price.Sale;
        }
        setValueBreakfast(Object.values(temp));
    };
    const onChangeMain = (checked, price) => {
        // update data in current table
        const res = {
            price: _.isEmpty(price) ? breakfastMain.price : price.Norm,
            priceSale: _.isEmpty(price) ? breakfastMain.priceSale : price.Sale,
            check: checked
        };
        setBreakfastMain(res);
    };
    const utility = (content) => {
        const data = _.get(allData, `RoomAmenities.${content}`, []);
        const list = [];
        for (const item of data) {
            const dataList = getContentArray(content);
            const temp = dataList.find(({ ID }) => ID === item);
            const element = <li key={item}>{temp?.Name}</li>;
            list.push(element);
        }
        return list;
    };
    const roomsOther = () => {
        const list = [];
        const mapBreakfast = _.keyBy(breakfast, '_id');
        for (const item of room) {
            const checkBreakfast = mapBreakfast[item.ID];
            const percent = Math.round(100 - (checkBreakfast.priceSale * 100) / checkBreakfast.price);
            const value = (
                <div className='col-lg-4 room-block-two py-3' key={item.ID}>
                    <div className='inner-box pb-4'>
                        <div className='image'>
                            <img src={`${environments.PUBLIC_IMAGE}/image/room/${location.state}/${item.ImageMain}`} alt='' />
                            <div className='sale-off'>-{percent}%</div>
                            <div className='d-flex justify-content-center'>
                                <div className='price'>
                                    <div className='row mx-3'>
                                        <div className='col-6 px-0'>
                                            <RadioButton
                                                inputId={`noBreakfast` + item.ID}
                                                name='breakfast'
                                                onChange={() => onChange(item.ID, false, item.Price)}
                                                checked={!checkBreakfast?.check || false}
                                            />
                                            <label htmlFor={`noBreakfast` + item.ID} className='px-2 fs-md'>
                                                No Breakfast
                                            </label>
                                        </div>
                                        <div className='col-6 px-0'>
                                            <RadioButton
                                                inputId={`haveBreakfast_` + item.ID}
                                                name='breakfast'
                                                onChange={() => onChange(item.ID, true, item.PriceBreakFast)}
                                                checked={checkBreakfast?.check || false}
                                            />
                                            <label htmlFor={`haveBreakfast_` + item.ID} className='px-2 fs-md'>
                                                Have Breakfast
                                            </label>
                                        </div>
                                    </div>
                                    <div className='row p-2'>
                                        <div className='col-md-6 price-original'>
                                            {Number(checkBreakfast?.price || 0).toLocaleString('en')} VND
                                        </div>
                                        <div className='col-md-6 price-sale'>
                                            {Number(checkBreakfast?.priceSale || 0).toLocaleString('en')} VND
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='px-3'>
                            <h3 className='blog-page-title'>
                                <a
                                    href='/#'
                                    className='fp-24 text-ellipsis-1line'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirect(generatePath(ROUTER.ROOM_DETAIL), ROUTER.ID, { id: item.ID }, item.ID);
                                    }}>
                                    {item.Name}
                                </a>
                            </h3>
                            <div className='icon-list'>
                                <ul className='row px-1'>
                                    <li className='d-flex align-items-center col-6 py-2'>
                                        <span className='icon bed mx-0'></span>
                                        <span className='px-2 fs-md text-ellipsis-1line'>{item.RoomAmenities.main.bed}</span>
                                    </li>
                                    <li className='d-flex align-items-center col-6 py-2'>
                                        <span className='icon blueprint mx-0'></span>
                                        <span className='px-2 fs-md text-ellipsis-1line'>
                                            {item.RoomAmenities.main.blueprint}
                                            <span className='px-1'>
                                                m<sup>2</sup>
                                            </span>
                                        </span>
                                    </li>
                                    <li className='d-flex align-items-center col-6 py-2'>
                                        <span className='icon direction mx-0'></span>
                                        <span className='px-2 fs-md text-ellipsis-1line'>{item.RoomAmenities.main.direction}</span>
                                    </li>
                                    <li className='d-flex align-items-center col-6 py-2'>
                                        <span className='icon people mx-0'></span>
                                        <span className='px-2 fs-md text-ellipsis-1line'>{item.RoomAmenities.main.people}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className='text-two text-ellipsis-2line mh-56'>{item.SpecialDescription}</div>
                            <div className='row mx-0'>
                                <Button className='btn-action-sm' variant='default'>
                                    Book Now
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
            list.push(value);
        }
        return <div className='position-relative row'>{list}</div>;
    };
    const thumbnailTemplate = (item) => {
        return <img src={`${environments.PUBLIC_IMAGE}/image/room/${location.state}/${item}`} alt={item} />;
    };
    return (
        <div className='auto-container rooms h-100'>
            <div className='row w-100 card px-0 border-0 pt-5'>
                <Galleria
                    value={allData?.ImageList}
                    activeIndex={activeIndex}
                    onItemChange={(e) => setActiveIndex(e.index)}
                    responsiveOptions={responsiveOptions}
                    numVisible={5}
                    item={itemTemplate}
                    thumbnail={thumbnailTemplate}
                    showItemNavigators
                />
            </div>
            <div className='check-availability style-seven row mx-0'>
                <div className='flex col-md-3 col-sm-12 gap-2'>
                    <p className='py-2 mb-0'>Check in</p>
                    <Calendar value={checkIn} onChange={(e) => setCheckIn(e.value)} className='w-100' placeholder='Check In' />
                </div>
                <div className='flex col-md-3 col-sm-12 gap-2'>
                    <p className='py-2 mb-0'>Check Out</p>
                    <Calendar value={checkOut} onChange={(e) => setCheckOut(e.value)} className='w-100' placeholder='Check Out' />
                </div>
                <div className='flex col-md-3 col-sm-12 gap-2'>
                    <p className='py-2 mb-0'>&nbsp;</p>
                    <InputText
                        className='form-control my-0 py-2'
                        value={`${selectedAdults} Adults, ${selectedChildren} Children`}
                        readOnly
                        onClick={(e) => op.current.toggle(e)}
                    />
                    <OverlayPanel ref={op} className='w-slider'>
                        <p className='fs-lg fw-bold'>1 Bedroom</p>
                        <div className='row align-items-center py-2 border-bottom'>
                            <p className='py-2 mb-0 col-4'>Adults</p>
                            <div className='col-8'>
                                <InputNumber
                                    value={selectedAdults}
                                    onValueChange={(e) => setSelectedAdults(e.value)}
                                    showButtons
                                    buttonLayout='horizontal'
                                    step={1}
                                    min={0}
                                    max={10}
                                    incrementButtonIcon='pi pi-plus disabled'
                                    decrementButtonIcon='pi pi-minus'
                                />
                            </div>
                        </div>
                        <div className='row align-items-center py-2 border-bottom'>
                            <p className='py-2 mb-0 col-4'>Children</p>
                            <div className='col-8'>
                                <InputNumber
                                    value={selectedChildren}
                                    onValueChange={(e) => setSelectedChildren(e.value)}
                                    showButtons
                                    buttonLayout='horizontal'
                                    step={1}
                                    min={0}
                                    max={10}
                                    incrementButtonIcon='pi pi-plus'
                                    decrementButtonIcon='pi pi-minus'
                                />
                            </div>
                        </div>
                    </OverlayPanel>
                </div>
                <div className='flex col-md-3 col-sm-12 gap-2'>
                    <p className='py-2 mb-0'>&nbsp;</p>
                    <Button variant='default' className='btn-action w-100'>
                        Check Availability
                    </Button>
                </div>
            </div>
            <div className='row room-details'>
                <div className='col-lg-8'>
                    <div className='d-flex justify-content-center'>
                        <div className='price w-100'>
                            <div className={_.isEmpty(allData.PriceBreakFast) ? 'd-none' : 'row mx-3'}>
                                <div className='col-md-3 px-0 col-sm-12'>
                                    <RadioButton
                                        inputId='noBreakfast'
                                        name='breakfasts'
                                        onChange={() => onChangeMain(false, allData.Price)}
                                        checked={!breakfastMain?.check || false}
                                    />
                                    <label htmlFor='noBreakfast' className='px-2 fs-md'>
                                        No Breakfast
                                    </label>
                                </div>
                                <div className='col-md-3 px-0 col-sm-12'>
                                    <RadioButton
                                        inputId='haveBreakfast_'
                                        name='breakfasts'
                                        onChange={() => onChangeMain(true, allData.PriceBreakFast)}
                                        checked={breakfastMain?.check || false}
                                    />
                                    <label htmlFor='haveBreakfast' className='px-2 fs-md'>
                                        Have Breakfast
                                    </label>
                                </div>
                            </div>
                            <div className='row p-2'>
                                <div className='col-md-3 price-original fp-20'>
                                    {Number(breakfastMain?.price || 0).toLocaleString('en')} VND
                                </div>
                                <div className='col-md-3 price-sale fp-20'>
                                    {Number(breakfastMain?.priceSale || 0).toLocaleString('en')} VND
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2 className='sec-title px-3'>{allData.Name}</h2>
                        <div className='room-title px-3'>{allData.Description}</div>
                    </div>
                    <div className='row py-4'>
                        <div className='col-md-6 py-2'>
                            <div className='d-flex align-items-center'>
                                <span className='icon blueprint mx-3'></span>
                                <span className='px-3 fs-md'>
                                    {allData.RoomAmenities?.main.blueprint}
                                    <span className='px-1'>
                                        m<sup>2</sup>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className='d-flex align-items-center'>
                                <span className='icon bed mx-3'></span>
                                <span className='px-3 fs-md'>{allData.RoomAmenities?.main.bed}</span>
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className='d-flex align-items-center'>
                                <span className='icon people mx-3'></span>
                                <span className='px-3 fs-md'>{allData.RoomAmenities?.main.people}</span>
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className='d-flex align-items-center'>
                                <span className='icon direction icon-sea mx-0'></span>
                                <span className='px-3 fs-md'>{allData.RoomAmenities?.main.direction}</span>
                            </div>
                        </div>
                    </div>
                    <h5 className='fw-bold px-3'> Tiện nghi phòng</h5>
                    <div className='row pb-4 px-3'>
                        <div className='col-md-6 py-2'>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Hỗ trợ người khuyết tật</span>
                            </div>
                            <ul>{utility(UTILITY.DISABILITY)}</ul>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Phòng ngủ</span>
                            </div>
                            <ul>{utility(UTILITY.BATHROOM)}</ul>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Cho gia đình</span>
                            </div>
                            <ul>{utility(UTILITY.FAMILY)}</ul>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Ăn uống</span>
                            </div>
                            <ul>{utility(UTILITY.FOOD)}</ul>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Phòng tắm</span>
                            </div>
                            <ul>{utility(UTILITY.BEDROOM)}</ul>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Giải trí</span>
                            </div>
                            <ul>{utility(UTILITY.ENTERTAINMENT)}</ul>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Internet</span>
                            </div>
                            <ul>{utility(UTILITY.INTERNET)}</ul>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Khác</span>
                            </div>
                            <ul>{utility(UTILITY.MORE)}</ul>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='check-availability style-seven pt-0 pb-5 mb-4'>
                        <h4 className='fw-bold'>Book Now</h4>
                        <p className='text-center'>
                            <span className='price-original fs-xxl'> {Number(breakfastMain?.price || 0).toLocaleString('en')} VND</span>
                            <span className='price-sale fs-xxl'> {Number(breakfastMain?.priceSale || 0).toLocaleString('en')} VND</span>
                        </p>
                        <div className='align-items-center justify-content-center d-flex w-100 px-0 pt-3 pb-4'>
                            <InputNumber
                                value={card}
                                onValueChange={(e) => setCard(e.value)}
                                showButtons
                                min={0}
                                max={10}
                                buttonLayout='horizontal'
                                decrementButtonClassName='p-button-secondary'
                                incrementButtonClassName='p-button-secondary'
                                incrementButtonIcon='pi pi-plus'
                                decrementButtonIcon='pi pi-minus'
                            />
                        </div>
                        <div className='row mx-3 pb-3'>
                            <div className='col-6 px-0'>
                                <RadioButton inputId='oneBed' name='bed' onChange={() => setSelectedBed(1)} checked={selectBed === 1} />
                                <label htmlFor='oneBed' className='px-2 fs-md'>
                                    1 King Bed
                                </label>
                            </div>
                            <div className='col-6 px-0'>
                                <RadioButton inputId='twoBed' name='bed' onChange={() => setSelectedBed(2)} checked={selectBed === 2} />
                                <label htmlFor='twoBed' className='px-2 fs-md'>
                                    2 Twin Beds
                                </label>
                            </div>
                        </div>
                        <Button variant='default' className='btn-action w-100'>
                            Add To Card
                        </Button>
                    </div>
                </div>
            </div>
            <div className='container-rooms py-5'>
                <h2 className='sec-title text-center'>Other Room</h2>
                {roomsOther()}
            </div>
        </div>
    );
}
