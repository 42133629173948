export default function Map() {
    return (
        <div className="row mx-0'">
            <div className='col-md-9 col-sm-12 px-0'>
                <iframe
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3833.8410749030654!2d108.23597414781396!3d16.07373474503646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3142176c1d4bf767%3A0x502fd69b437fbf81!2sCordial%20Grand%20Hotel!5e0!3m2!1sfr!2sus!4v1711035653000!5m2!1sfr!2sus'
                    width='100%'
                    height='450'
                    allowFullScreen=''
                    loading='lazy'
                    referrerPolicy='no-referrer-when-downgrade'></iframe>
            </div>
            <div className='col-md-3 col-sm-12 px-4'>
                <p className='fw-bold text-center mb-2'>VỊ TRÍ SIÊU PHẨM</p>
                <p className='fw-bold txt-main text-center mb-1'>NGHỈ DƯỠNG &ldquo;ĐẲNG CẤP&rdquo;</p>
                <div className='align-items-center d-flex py-2 w-100 fw-bold txt-main'>4 mins</div>
                <div className='align-items-center d-flex py-2 w-100'>Đến biển Mỹ Khê</div>
                <div className='align-items-center d-flex py-2 w-100 ps-1 fw-bold txt-main'>12 mins</div>
                <div className='align-items-center d-flex py-2 w-100'>Đến sân bay quốc tế Đà Nẵng</div>
                <div className='align-items-center d-flex py-2 w-100 fw-bold txt-main'>6 mins</div>
                <div className='align-items-center d-flex py-2 w-100'>Đến Cầu Rồng</div>
                <div className='align-items-center d-flex py-2 w-100 ps-1 fw-bold txt-main'>7 mins</div>
                <div className='align-items-center d-flex py-2 w-100'>Cầu tình yêu</div>
                <div className='align-items-center d-flex py-2 w-100 ps-1 fw-bold txt-main'>17 mins</div>
                <div className='align-items-center d-flex py-2 w-100'>Đến chùa Linh Ứng</div>
            </div>
        </div>
    );
}
