import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'react-bootstrap';
import RightBlog from './right-blog';
export default function BlogDetail() {
    return (
        <div>
            <div className='blogs py-5 row'>
                <div className='col-md-8 col-sm-12'>
                    <div className='my-3 border'>
                        <div className='blog-thumb'>
                            <Image src='https://demo2.pavothemes.com/seasona/wp-content/uploads/2022/04/gallery_pic7.jpg' preview />
                            <div className='blog-meta-top'>
                                <Button className='bnt-blog-category'>INTERIOR</Button>
                            </div>
                        </div>
                        <div className='single-blog-details-inner'>
                            <div className='blog-meta-left pb-3 pt-5 px-4'>
                                <span>
                                    <i className='pi pi-user pe-2'></i>By: Admin
                                </span>
                                <span>
                                    <i className='pi pi-calendar ps-4 pe-2' aria-hidden='true'></i>March 19, 2024
                                </span>
                                <span>
                                    <i className='pi pi-comments ps-4 pe-2' aria-hidden='true'></i>0 Comments
                                </span>
                            </div>
                            <div className='px-4'>
                                <div className='single-blog-content'>
                                    <p>
                                        Quisque pretium fermentum quam, sit amet cursus ante sollicitudin vel. Morbi consequat risus
                                        consequat, porttitor orci sit amet, iaculis nisl. Integer quis sapien neceli ultrices euismod sit
                                        amet id lacus. Sed a imperdiet erat. Duis eu est dignissim lacus dictum hendrerit quis vitae mi.
                                        Fusce eu nulla ac nisi cursus tincidun. Interdum et malesuada fames ac ante ipsum primis in
                                        faucibus. Integer tristique sem eget l eo faucibus porttitor. Nulla vitae metus tincidunt, varius
                                        nunc quis, porta nulla. Pellentesque vel dui nec libero auctor pretium id sed arcu. Nunc consequat
                                        diam id nisl blani dinisim. Etiam commodo diam dolor, at scelerisque sem finibus sit amet. Curabitur
                                        id lectus eget purus finibus laoreet.
                                    </p>
                                    <blockquote>
                                        <p>
                                            Nulla facilisi. Sedeuter nunc vouta miss mollis sapien vel, conseyer tureution yer vintane in
                                            libero semper. Quisque ravida eros ut turpis interdum ornare. Inter miss they adama seder a
                                            imerdie fames ac ante ipsum primis in faucibus.
                                        </p>
                                        <p>
                                            <cite>Micheal Martin</cite>
                                        </p>
                                    </blockquote>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <Image
                                                src='https://demo2.pavothemes.com/seasona/wp-content/uploads/2022/04/gallery_pic7.jpg'
                                                preview
                                                className='img-blog'
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <Image
                                                src='https://demo2.pavothemes.com/seasona/wp-content/uploads/2022/04/gallery_pic7.jpg'
                                                preview
                                                className='img-blog'
                                            />
                                        </div>
                                    </div>
                                    <p>
                                        Design pretium fermentum quam, sit amet cursus ante sollicitudin vel. Morbi consequat risus
                                        consequat, porttitor orci sit amet, iaculis nisl. Integer quis sapien neceli ultrices euismod sit
                                        amet id lacus. Sed a imperdiet erat. Duis eu est dignissim lacus dictum hendrerit quis vitae mi.
                                        Fusce eu nulla ac nisi cursus tincidun. Interdum et malesuada fames ac ante ipsum primis in
                                        faucibus. Integer tristique sem eget leo faucibus porttitor.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='row mx-0 px-4'>
                            <Button variant='social'>
                                <span className='pi pi-twitter' />
                            </Button>
                            <Button variant='social'>
                                <span className='pi pi-instagram' />
                            </Button>
                            <Button variant='social'>
                                <span className='pi pi-facebook' />
                            </Button>
                        </div>
                        <div className='post-details-footer'>
                            <div className='widget bg-transparent mb-0 pb-0'>
                                <h5 className='widget-title-detail mb-3'>Categories: </h5>
                                <div className='btn-list'>
                                    <Button className='bnt-blog-category'>INTERIOR</Button>
                                </div>
                            </div>
                            <div className='widget bg-transparent mb-0'>
                                <h5 className='widget-title-detail mb-3'>Tags: </h5>
                                <div className='btn-list'>
                                    <Button className='btn-tag mx-1' variant='default'>
                                        Accommodation
                                    </Button>
                                    <Button className='btn-tag mx-1' variant='default'>
                                        Spa
                                    </Button>
                                    <Button className='btn-tag mx-1' variant='default'>
                                        Restaurant
                                    </Button>
                                    <Button className='btn-tag mx-1' variant='default'>
                                        Event
                                    </Button>
                                    <Button className='btn-tag mx-1' variant='default'>
                                        Services
                                    </Button>
                                    <Button className='btn-tag mx-1' variant='default'>
                                        Room
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='comments-area border p-4'>
                        <h3> Leave Comment </h3>
                        <div className='row py-2'>
                            <div className='col-md-6 col-sm-12 form-group'>
                                <InputText className='form-control mb-2' placeholder='Your Name' />
                            </div>
                            <div className='col-md-6 col-sm-12 form-group'>
                                <InputText className='form-control mb-2' placeholder=' Email address' />
                            </div>
                        </div>
                        <div className='row py-32'>
                            <div className='col-md-12 col-sm-12 form-group'>
                                <InputTextarea className='form-control' placeholder='Comment' />
                            </div>
                        </div>
                        <div className='row py-3 px-2'>
                            <Button className='btn-action' variant='default'>
                                Post Comment
                            </Button>
                        </div>
                    </div>
                </div>
                <RightBlog />
            </div>
        </div>
    );
}
