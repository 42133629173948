export const LOGIN_URL = '/api/uaa/authentication/login';
export const VERIFY_URL = '/api/uaa/authentication/verify';
export const LOGOUT_URL = '/api/uaa/authentication/logout';
export const CONTACT_URL = '/api/contact';
export const ROOM_URL = '/api/room';
export const ROOM_DETAIL_URL = '/api/room/detail';
export const ROOM_RECENT_URL = '/api/room/recent';
export const ROOM_VERIFY_NAME = 'api/room/verify';
export const FM_URL = '/api/file-manager';
export const FM_UPLOAD = '/api/file-manager/upload';
export const ROOM_REMOVE_IMAGE_URL = '/api/room/remove-image';
export const SERVICE_REMOVE_IMAGE_URL = '/api/service/remove-image';
export const SERVICE_URL = '/api/service';
export const GALLERY_REMOVE_IMAGE_URL = '/api/gallery/remove-image';
export const GALLERY_URL = '/api/gallery';
export const SERVICE_DETAIL_URL = '/api/service/detail';
export const UTILITIES_URL = '/api/room/utilities';
export const CAROUSEL_URL = '/api/carousel';
export const IGNORE_AUTHOR = [LOGIN_URL, VERIFY_URL, LOGOUT_URL];
