import { LANG_JP } from 'app/constants';
import registerServices from 'app/services/core/register-global';
import store from 'app/store';
import i18n from 'app/translations';
import { App } from 'app/views/pages';
import 'assets/styles/styles.scss';
import env from 'environments';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/mira/theme.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

if (window.localStorage.getItem('REACT_APP_VERSION') !== env.VERSION) {
    const lang = localStorage.getItem('language') || LANG_JP;
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.localStorage.setItem('language', lang);
}
window.localStorage.setItem('REACT_APP_VERSION', env.VERSION);
registerServices();
const root = createRoot(document.getElementById('root'));
root.render(
    <StrictMode>
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <App />
            </Provider>
        </I18nextProvider>
    </StrictMode>
);
serviceWorker.unregister();
