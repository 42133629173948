import { TabPanel, TabView } from 'primereact/tabview';
import GymGallery from './gym';
import RestaurantGallery from './restaurant';
import RoomGallery from './room';
import SpaGallery from './spa';
import SwimmingGallery from './swimming';
export default function Galleries() {
    return (
        <div className='bg-white'>
            <div className='auto-container gallery py-5'>
                <TabView className='px-0'>
                    <TabPanel header='Rooms & Suites'>
                        <RoomGallery />
                    </TabPanel>
                    <TabPanel header='Spa & Massage'>
                        <SpaGallery />
                    </TabPanel>
                    <TabPanel header='Restaurants'>
                        <RestaurantGallery />
                    </TabPanel>
                    <TabPanel header='Swimming Pool'>
                        <SwimmingGallery />
                    </TabPanel>
                    <TabPanel header='Gym & Fitness'>
                        <GymGallery />
                    </TabPanel>
                </TabView>
            </div>
        </div>
    );
}
