export const aboutData = [
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria1.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria1s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 1 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14',
        title: 'Rooms & Suites 1'
    },
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria2.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria2s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 1 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14',
        title: 'Rooms & Suites 2'
    },
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria3.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria3s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 1 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14',
        title: 'Rooms & Suites 3'
    },
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria4.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria4s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 1 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14',
        title: 'Rooms & Suites 4'
    },
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria5.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria5s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 1 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14',
        title: 'Rooms & Suites 5'
    },
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria6.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria6s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 1 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14',
        title: 'Rooms & Suites 6'
    },
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria7.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria7s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 1 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14',
        title: 'Rooms & Suites 7'
    },
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria8.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria8s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 1 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14',
        title: 'Rooms & Suites 8'
    },
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria9.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria9s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 1 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14',
        title: 'Rooms & Suites 9'
    },
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria10.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria10s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 1 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14',
        title: 'Rooms & Suites 10'
    },
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria11.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria11s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 1 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14 Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14',
        title: 'Rooms & Suites 11'
    },
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria12.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria12s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 12',
        title: 'Rooms & Suites 12'
    },
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria13.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria13s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 13',
        title: 'Rooms & Suites 13'
    },
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria14.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria14s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 14',
        title: 'Rooms & Suites 14'
    },
    {
        itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria15.jpg',
        thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria15s.jpg',
        alt: 'Bluebell is to bring together our visitors societies and spirits with our own, communicating enthusiasm and liberality 15',
        title: 'Rooms & Suites 15'
    }
];
