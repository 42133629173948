import { ROUTER } from 'app/constants';
import { commonService, roomService } from 'app/services';
import { useRedirect } from 'app/utils/browser';
import environments from 'environments';
import _ from 'lodash';
import { RadioButton } from 'primereact/radiobutton';
import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
export default function RoomList() {
    const [data, setData] = useState([]);
    const redirect = useRedirect();
    const [breakfast, setValueBreakfast] = useState([]);
    /**
     * fetch data from api
     * @param {Object} input
     * @returns
     */
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await roomService.search({ selectedCategory: [1, 2] });
        const res = data.map((v) => {
            return { _id: v.ID, check: false, price: v.Price.Norm, priceSale: v.Price.Sale };
        });
        setValueBreakfast(res);
        setData(data);
        commonService.preloader.hide();
        return true;
    };
    /**
     * Update user permission
     * @param {String} _id
     * @param {Number} permission
     */
    const onChange = (_id, permission, price) => {
        const temp = _.keyBy(breakfast, '_id');
        // update data in current table
        temp[_id].check = permission;
        temp[_id].percent = permission;
        if (!_.isEmpty(price)) {
            temp[_id].price = price.Norm;
            temp[_id].priceSale = price.Sale;
        }
        setValueBreakfast(Object.values(temp));
    };
    /**
     * Fetch first render data
     */
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
    }, []);
    const rooms = (type) => {
        const result = !type ? data : data.filter((v) => v.Category === type);
        const list = [];
        const mapBreakfast = _.keyBy(breakfast, '_id');
        for (const item of result) {
            const checkBreakfast = mapBreakfast[item.ID];
            const percent = Math.round(100 - (checkBreakfast.priceSale * 100) / checkBreakfast.price);
            const value = (
                <div className='col-lg-4 room-block-two py-3' key={item.ID}>
                    <div className='inner-box pb-4'>
                        <div className='image'>
                            <img src={`${environments.PUBLIC_IMAGE}/image/room/${item.ID}/${item.ImageMain}`} alt='' />
                            <div className='sale-off'>-{percent}%</div>
                            <div className='d-flex justify-content-center'>
                                <div className='price'>
                                    <div className={_.isEmpty(item.PriceBreakFast) ? 'd-none' : 'row mx-3'}>
                                        <div className='col-6'>
                                            <RadioButton
                                                inputId={`noBreakfast` + item.ID}
                                                name='breakfast'
                                                onChange={() => onChange(item.ID, false, item.Price)}
                                                checked={!checkBreakfast?.check || false}
                                            />
                                            <label htmlFor={`noBreakfast` + item.ID} className='px-2 fs-md'>
                                                No Breakfast
                                            </label>
                                        </div>
                                        <div className='col-6'>
                                            <RadioButton
                                                inputId={`haveBreakfast_` + item.ID}
                                                name='breakfast'
                                                onChange={() => onChange(item.ID, true, item.PriceBreakFast)}
                                                checked={checkBreakfast?.check || false}
                                            />
                                            <label htmlFor={`haveBreakfast_` + item.ID} className='px-2 fs-md'>
                                                Have Breakfast
                                            </label>
                                        </div>
                                    </div>
                                    <div className='row p-2'>
                                        <div className='col-md-6 price-original'>
                                            {Number(checkBreakfast?.price || 0).toLocaleString('en')} VND
                                        </div>
                                        <div className='col-md-6 price-sale'>
                                            {Number(checkBreakfast?.priceSale || 0).toLocaleString('en')} VND
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='px-3'>
                            <h3 className='blog-page-title'>
                                <a
                                    href='/#'
                                    className='fp-24 text-ellipsis-1line'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirect(generatePath(ROUTER.ROOM_DETAIL), ROUTER.ID, { id: item.ID }, item.ID);
                                    }}>
                                    {item.Name}
                                </a>
                            </h3>
                            <div className='icon-list'>
                                <ul className='row px-1'>
                                    <li className='d-flex align-items-center col-6 py-2'>
                                        <span className='icon bed mx-0'></span>
                                        <span className='px-2 fs-md text-ellipsis-1line'>{item.RoomAmenities.main.bed}</span>
                                    </li>
                                    <li className='d-flex align-items-center col-6 py-2'>
                                        <span className='icon blueprint mx-0'></span>
                                        <span className='px-2 fs-md text-ellipsis-1line'>
                                            {item.RoomAmenities.main.blueprint}
                                            <span className='px-1'>
                                                m<sup>2</sup>
                                            </span>
                                        </span>
                                    </li>
                                    <li className='d-flex align-items-center col-6 py-2'>
                                        <span className='icon direction mx-0'></span>
                                        <span className='px-2 fs-md text-ellipsis-1line'>{item.RoomAmenities.main.direction}</span>
                                    </li>
                                    <li className='d-flex align-items-center col-6 py-2'>
                                        <span className='icon people mx-0'></span>
                                        <span className='px-2 fs-md text-ellipsis-1line'>{item.RoomAmenities.main.people}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className='text-two text-ellipsis-2line mh-56'>{item.SpecialDescription}</div>
                            <div className='row mx-0'>
                                <Button className='btn-action-sm' variant='default'>
                                    Book Now
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
            list.push(value);
        }
        return <div className='position-relative row'>{list}</div>;
    };
    return (
        <div>
            <div className='auto-container row container-rooms'>
                <div className='col-12'>
                    <TabView className='px-0 py-3'>
                        <TabPanel header='All'>{rooms(0)}</TabPanel>
                        <TabPanel header='Cordial Hotel'>{rooms(1)}</TabPanel>
                        <TabPanel header='Cordial Grand Hotel'>{rooms(2)}</TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    );
}
