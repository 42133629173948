/* eslint-disable no-undef */
export default {
    API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
    SOCKET_ENDPOINT: process.env.REACT_APP_SOCKET_ENDPOINT,
    SOCKET_PATH: process.env.REACT_APP_SOCKET_PATH,
    BASE_NAME: process.env.REACT_APP_BASE_NAME || '/',
    VERSION: process.env.REACT_APP_VERSION || '0.0.1',
    BUILD: process.env.REACT_APP_BUILD,
    PUBLIC_IMAGE: process.env.REACT_APP_PUBLIC_IMAGE_URL
};
