import { LANG_JP } from 'app/constants';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// add resource
import transEN from './lang/en';
import transJP from './lang/jp';
i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            'en-US': { translation: transEN },
            'ja-JP': { translation: transJP }
        },
        lng: localStorage.getItem('language') || 'ja-JP',
        fallbackLng: 'en-US',
        debug: true,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        defaultNS: 'translation',
        //react config
        react: {
            //     bindI18n: 'languageChanged',
            //     bindI18nStore: '',
            //     transEmptyNodeValue: '',
            //     transSupportBasicHtmlNodes: true,
            //     transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            useSuspense: false
        }
    });
i18n.on('languageChanged', function (language) {
    localStorage.setItem('language', language);
    if (language === LANG_JP) {
        document.body.classList.add('font-jp');
    } else document.body.classList.remove('font-jp');
});
export const t = i18n.t.bind(i18n);
export default i18n;
