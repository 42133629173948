import { ENUM_GALLERY } from 'app/constants';
import { commonService } from 'app/services';
import galleryService from 'app/services/gallery/gallery.service';
import environments from 'environments';
import { Image } from 'primereact/image';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
export default function SpaGallery() {
    const [image, setData] = useState([]);
    /**
     * fetch data from db with ID
     * @returns
     */
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await galleryService.get({ category: ENUM_GALLERY.SPA });
        setData(data.ImageList);
        commonService.preloader.hide();
        return true;
    };
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
    }, []);
    const renderImage = () => {
        const page = image.length % 4 === 0 ? Math.floor(image.length / 4) : Math.floor(image.length / 4) + 1;
        const list = [];
        for (let i = 0; i < page; i++) {
            if (i % 2 === 0) {
                const item = (
                    <Row className='m-0 gallery-list switch-right col-md-12 col-sm-12'>
                        <div className={i * 4 < image.length ? 'col-md-6 col-sm-12 px-0' : 'd-none'}>
                            <div className='img-right'>
                                <Image
                                    src={`${environments.PUBLIC_IMAGE}/image/gallery/${ENUM_GALLERY.SPA}/${image[i * 4]}`}
                                    alt='Image'
                                    preview
                                    className='img-min-screen'
                                />
                            </div>
                        </div>
                        <div className={i * 4 + 1 < image.length ? 'col-md-6 col-sm-12 px-0 h-100' : 'd-none'}>
                            <div className='img-left'>
                                <Row className='m-0 gallery-height'>
                                    <div className='col-md-6 col-sm-12 px-0 h-100'>
                                        <div className='img-gs'>
                                            <Image
                                                src={`${environments.PUBLIC_IMAGE}/image/gallery/${ENUM_GALLERY.SPA}/${image[i * +1]}`}
                                                alt='Image'
                                                preview
                                                className='img-min-screen'
                                            />
                                        </div>
                                    </div>
                                    <div className={i * 4 + 2 < image.length ? 'col-md-6 col-sm-12 px-0 h-100' : 'd-none'}>
                                        <div className='img-ge'>
                                            <Image
                                                src={`${environments.PUBLIC_IMAGE}/image/gallery/${ENUM_GALLERY.SPA}/${image[i * 4 + 2]}`}
                                                alt='Image'
                                                preview
                                                className='img-min-screen'
                                            />
                                        </div>
                                    </div>
                                </Row>
                                <Row className='m-0 gallery-height'>
                                    <div className={i * 4 + 3 < image.length ? 'col-md-12 col-sm-12 px-0 h-100' : 'd-none'}>
                                        <div className='img-bottom'>
                                            <Image
                                                src={`${environments.PUBLIC_IMAGE}/image/gallery/${ENUM_GALLERY.SPA}/${image[i * 4 + 3]}`}
                                                alt='Image'
                                                preview
                                                className='img-min-screen'
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Row>
                );
                list.push(item);
            } else {
                const value = (
                    <Row className='m-0 gallery-list my-2 switch-left col-sm-12'>
                        <div className={i * 4 + 3 < image.length ? 'col-md-6 col-sm-12 px-0 img-big' : 'd-none'}>
                            <div className='img-left'>
                                <Image
                                    src={`${environments.PUBLIC_IMAGE}/image/gallery/${ENUM_GALLERY.SPA}/${image[i * 4 + 3]}`}
                                    alt='Image'
                                    preview
                                    className='img-min-screen'
                                />
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12 px-0 h-100'>
                            <div className='img-group'>
                                <Row className='m-0 gallery-height'>
                                    <div className={i * 4 + 1 < image.length ? 'col-md-6 col-sm-12 px-0 h-100' : 'd-none'}>
                                        <div className='img-right'>
                                            <Image
                                                src={`${environments.PUBLIC_IMAGE}/image/gallery/${ENUM_GALLERY.SPA}/${image[i * 4 + 1]}`}
                                                alt='Image'
                                                preview
                                                className='img-min-screen'
                                            />
                                        </div>
                                    </div>
                                    <div className={i * 4 < image.length ? 'col-md-6 col-sm-12 px-0 h-100' : 'd-none'}>
                                        <div className='image-about h-100'>
                                            <Image
                                                src={`${environments.PUBLIC_IMAGE}/image/gallery/${ENUM_GALLERY.SPA}/${image[i * 4]}`}
                                                alt='Image'
                                                preview
                                                className='img-min-screen'
                                            />
                                        </div>
                                    </div>
                                </Row>
                                <Row className='m-0 gallery-height'>
                                    <div className={i * 4 + 2 < image.length ? 'col-md-12 col-sm-12 px-0 h-100' : 'd-none'}>
                                        <div className='img-bottom'>
                                            <Image
                                                src={`${environments.PUBLIC_IMAGE}/image/gallery/${ENUM_GALLERY.SPA}/${image[i * 4 + 2]}`}
                                                alt='Image'
                                                preview
                                                className='img-min-screen'
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Row>
                );
                list.push(value);
            }
        }
        return list;
    };
    return <div>{renderImage()}</div>;
}
