import Map from '../common/map';
export default function LocationElement() {
    return (
        <div className='auto-container common-back-ground'>
            <div className='text-center sec-title pt-4'>
                <div className='sub-title'>Location Beautiful</div>
                <h2 className='sec-title'>Discover Affordable Tours</h2>
            </div>
            <div className='pb-3'>
                <Map />
            </div>
        </div>
    );
}
