import { ROUTER } from 'app/constants';
import { ImgTour } from 'assets/image/header';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
export default function Tours() {
    const { t } = useTranslation();
    const disabledDay = moment({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const [date, setDate] = useState('');
    const [searchField, setSearchField] = useState('');
    const navigate = useNavigate();
    const tour = () => {
        const list = [];
        const data = [1, 2, 3, 4, 5, 6, 7, 8, 9];
        for (const item of data) {
            const value = (
                <div className='col-md-4 col-sm-12 px-1 py-3' key={item}>
                    <div className='mx-2'>
                        <div>
                            <Image src={ImgTour} alt='' preview />
                        </div>
                        <div className='events-body px-3'>
                            <div className='entity-header'>
                                <h2 className='event-title pt-3'>
                                    <a href='/tour-detail'>Nemo Enim Ipsam Voluptatem</a>
                                </h2>
                            </div>
                            <div className='pt-2'>
                                <p className='entity-body'>
                                    Aliquam erat volutpat. Duis ac turpis. Donec sit amet eros. Lorem ipsum dolor. Mauris fermentum dictum
                                    magna. Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget, elementum vel, cursus eleifend, elit.
                                </p>
                            </div>
                            <div className='row mx-0, px-3 pb-5'>
                                <Button className='btn-action-sm' variant='default' onClick={() => navigate(ROUTER.TOUR_DETAIL)}>
                                    {t('BTN_MORE_DETAIL')}
                                </Button>
                                <Button variant='social'>
                                    <span className='pi pi-instagram' />
                                </Button>
                                <Button variant='social'>
                                    <span className='pi pi-facebook' />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
            list.push(value);
        }
        return list;
    };
    return (
        <div className='auto-container events py-5'>
            <div className='row'>
                <div className='col-md-12 row'>
                    <div className='row mx-0 pt-4'>
                        <div className='col-md-5 col-sm-12 px-0 py-2'>
                            <Calendar
                                value={date}
                                onChange={(e) => {
                                    setDate(e.target.value);
                                }}
                                baseZIndex={1055}
                                maxDate={disabledDay.toDate()}
                                inputClassName='p-calendar-input fs-md'
                                placeholder={t('PLACEHOLDER_SELECT_DATE')}
                                showButtonBar
                                showIcon
                                selectionMode='range'
                                className='w-100 fs-md'
                                icon='icon calendar'
                                readOnlyInput={true}
                                clearButtonClassName='border btn-cancel-sm rounded calendar-btn fs-md h-100'
                                todayButtonClassName='border btn-action-sm rounded calendar-btn fs-md text-white border-0 px-4'
                            />
                        </div>
                        <div className='col-md-5 col-sm-12 search px-0 py-2'>
                            <div className='search position-relative px-2'>
                                <form action='' method=''>
                                    <InputText
                                        type='text'
                                        className='w-100'
                                        value={searchField}
                                        placeholder={t('PLACEHOLDER_SEARCH_HERE')}
                                        onChange={(e) => setSearchField(e.target.value)}
                                    />
                                    <Button type='submit' className='icons'>
                                        <i className='pi pi-search'></i>
                                    </Button>
                                </form>
                            </div>
                        </div>
                        <div className='col-md-2 col-sm-12 px-0 py-2'>
                            <Button className='w-100 h-60px btn-action border-0 mx-2'>{t('BTN_FIND_TOUR')}</Button>
                        </div>
                    </div>
                    {tour()}
                </div>
            </div>
        </div>
    );
}
