// import { Image } from 'primereact/image';
// import { Carousel } from 'primereact/carousel';
import { Galleria } from 'primereact/galleria';
import { Image } from 'primereact/image';
import { useState } from 'react';
import { Row } from 'react-bootstrap';
export default function Events() {
    const [activeIndex, setActiveIndex] = useState(0);
    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 1
        }
    ];
    const images = [
        'https://html.tonatheme.com/2023/bluebell/assets/images/resource/image-104.jpg',
        'https://html.tonatheme.com/2023/bluebell/assets/images/resource/image-104.jpg',
        'https://html.tonatheme.com/2023/bluebell/assets/images/resource/image-104.jpg',
        'https://html.tonatheme.com/2023/bluebell/assets/images/resource/image-104.jpg',
        'https://html.tonatheme.com/2023/bluebell/assets/images/resource/image-104.jpg',
        'https://html.tonatheme.com/2023/bluebell/assets/images/resource/image-104.jpg',
        'https://html.tonatheme.com/2023/bluebell/assets/images/resource/image-104.jpg',
        'https://html.tonatheme.com/2023/bluebell/assets/images/resource/image-104.jpg',
        'https://html.tonatheme.com/2023/bluebell/assets/images/resource/image-104.jpg',
        'https://html.tonatheme.com/2023/bluebell/assets/images/resource/image-104.jpg',
        'https://html.tonatheme.com/2023/bluebell/assets/images/resource/image-104.jpg'
    ];
    const itemTemplate = (item) => {
        return <img src={item} alt={item} style={{ width: '100%' }} />;
    };
    return (
        <div>
            <div className='blogs py-5 row w-100 meeting about'>
                <h2 className='py-3 text-center'>Hội nghị và sự kiện</h2>
                <div className=''>
                    <Galleria
                        value={images}
                        activeIndex={activeIndex}
                        onItemChange={(e) => setActiveIndex(e.index)}
                        responsiveOptions={responsiveOptions}
                        numVisible={5}
                        autoPlay
                        transitionInterval={2000}
                        showThumbnails={false}
                        item={itemTemplate}
                        showItemNavigators
                    />
                </div>
                <p className='mx-0 pb-3 pt-4'>
                    Trung tâm Hội nghị Quốc tế ở tầng 5 khách sạn, được bố trí liên hoàn bao gồm phòng hội nghị, phòng hội thảo, phòng khách
                    VIP và phòng Tea-break, phục vụ hơn 500 đại biểu với các trang thiết bị hiện đại đạt tiêu chuẩn quốc tế và các dịch vụ
                    bổ sung khác...
                </p>
                <h3 className='py-3 text-center'>GIÁ PHÒNG HỘI NGHỊ</h3>
                <div className='h-100'>
                    <div className='row align-items-center border border-bottom-0 px-0 mx-0'>
                        <div className='col-md-2 col-sm-12 border-end py-2 h-100 d-flex align-items-center'>
                            <div className='w-100 text-center'>
                                <div className='w-100 py-1 fw-bold'>Vị trí - kích thước</div>
                            </div>
                        </div>
                        <div className='col-md-5 col-sm-12 border-end py-2 h-100 d-flex align-items-center px-0 mx-0'>
                            <div className='w-100'>
                                <div className='w-100 py-1 fw-bold text-center'>Mô tả</div>
                            </div>
                        </div>
                        <div className='col-md-5 col-sm-12 row d-flex px-0 mx-0'>
                            <div className='w-100 align-items-center row h-100 px-0 mx-0'>
                                <div className='w-100 h-40-px text-center border-bottom pt-2 fw-bold'>Đơn giá (VNĐ)</div>
                                <div className='w-table-large h-40-px text-center fw-bold pt-2 border-end'>Ngày 5-8 tiếng</div>
                                <div className='w-table-small h-40-px text-center fw-bold pt-2'>Buổi 1-4 tiếng</div>
                            </div>
                        </div>
                    </div>
                    <div className='row align-items-center border border-bottom-0 px-0 mx-0'>
                        <div className='col-md-2 col-sm-12 border-end py-2 h-100 d-flex align-items-center px-0 mx-0'>
                            <div className='w-100 text-center'>
                                <div className='w-100 py-1 fw-bold px-2'>BALL ROOM</div>
                                <div className='w-100 py-1 fw-bold px-2'>Tầng 5: 340m2</div>
                                <div className='w-100 py-1 fw-bold px-2'>(13.5m - 25m)</div>
                            </div>
                        </div>
                        <div className='col-md-5 col-sm-12 border-end py-2 h-100 d-flex align-items-center px-0 mx-0'>
                            <div className='w-100'>
                                <div className='w-100 py-1 px-2'>
                                    - Vị trí: nằm ở Tầng 5 nhìn toàn cảnh sông Hàn thơ mộng và con đường Trần Phú
                                </div>
                                <div className='w-100 py-1 px-2'>- Sức chứa: Lớp học: 300 Khách</div>
                                <div className='w-100 py-1 px-2'>- Rạp hát: 500 Khách - Chữ U 180 Khách</div>
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-12 border-end py-2 h-100 d-flex align-items-center px-2 mx-0'>
                            <div className='w-100'>
                                <div className='w-100 py-1 px-2'>Trên 400 khách 12.000.000</div>
                                <div className='w-100 py-1 px-2'>Từ 301-400 khách 11.000.000</div>
                                <div className='w-100 py-1 px-2'>Từ 201-300 khách 9.000.000</div>
                                <div className='w-100 py-1 px-2'>Dưới 200 khách: 8.000.000</div>
                            </div>
                        </div>
                        <div className='col-md-2 col-sm-12 py-2 h-100 d-flex align-items-center px-0 mx-0'>
                            <div className='w-100'>
                                <div className='w-100 py-1 px-2'>9.000.000</div>
                                <div className='w-100 py-1 px-2'>8.000.000</div>
                                <div className='w-100 py-1 px-2'>7.000.000</div>
                                <div className='w-100 py-1 px-2'>6.000.000</div>
                            </div>
                        </div>
                    </div>
                    <div className='row align-items-center border border-bottom-0 px-0 mx-0'>
                        <div className='col-md-2 col-sm-12 border-end py-2 h-100 d-flex align-items-center px-0 mx-0'>
                            <div className='w-100 text-center'>
                                <div className='w-100 py-1 px-2 fw-bold'>SEMINAR</div>
                                <div className='w-100 py-1 px-2 fw-bold'>Tầng 4 : 95m2</div>
                                <div className='w-100 py-1 px-2 fw-bold'>(5.6m - 17m)</div>
                            </div>
                        </div>
                        <div className='col-md-5 col-sm-12 border-end py-2 h-100 d-flex align-items-center px-2 mx-0'>
                            <div className='w-100'>
                                <div className='w-100 py-1 px-2'>
                                    - Vị trí: nằm ở Tầng 4 nhìn toàn cảnh sông Hàn thơ mộng và con đường Trần Quốc Toản
                                </div>
                                <div className='w-100 py-1 px-2'>- Sức chứa: Lớp học: 80 Khách</div>
                                <div className='w-100 py-1 px-2'>- Rạp hát: 100 Khách - Chữ U 50 Khách</div>
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-12 border-end py-2 h-100 d-flex align-items-center px-0 mx-0'>
                            <div className='w-100'>
                                <div className='w-100 py-1 px-2'>5.000.000</div>
                            </div>
                        </div>
                        <div className='col-2 py-2 h-100 d-flex align-items-center px-0 mx-0'>
                            <div className='w-100'>
                                <div className='w-100 py-1 px-2'>3.500.000</div>
                            </div>
                        </div>
                    </div>
                    <div className='row align-items-center border px-0 mx-0'>
                        <div className='col-md-2 col-sm-12  border-end py-2 h-100 d-flex align-items-center px-0 mx-0'>
                            <div className='w-100 text-center'>
                                <div className='w-100 py-1 fw-bold px-2'>MEETING ROOM</div>
                                <div className='w-100 py-1 fw-bold px-2'>Tầng Trệt : 35m2</div>
                                <div className='w-100 py-1 fw-bold px-2'>(4.5m - 7.5m)</div>
                            </div>
                        </div>
                        <div className='col-md-5 col-sm-12 border-end py-2 h-100 d-flex align-items-center px-2 mx-0'>
                            <div className='w-100'>
                                <div className='w-100 py-1 px-2'>- Vị trí: nằm ở Tầng Trệt với không gian riêng biệt và ấm cúng</div>
                                <div className='w-100 py-1 px-2'>- Sức chứa: Lớp học: 30 Khách</div>
                                <div className='w-100 py-1 px-2'>- Rạp hát: 40 Khách - Chữ U 20 Khách</div>
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-12 border-end py-2 h-100 d-flex align-items-center px-0 mx-0'>
                            <div className='w-100'>
                                <div className='w-100 py-1 px-2'>1.400.000</div>
                            </div>
                        </div>
                        <div className='col-md-2 col-sm-12 py-2 h-100 d-flex align-items-center px-0 mx-0'>
                            <div className='w-100'>
                                <div className='w-100 py-1 px-2'>900.000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row align-items-center py-2'>
                    <u className='fw-bold py-2'>Ghi chú: </u>
                    <p className='px-3'>
                        - Giá trên bao gồm thuế VAT và phí phục vụ. Phòng có trang bị âm thanh - ánh sáng - điều hoà ,bục phát biểu, màn
                        chiếu ,hoa đặt bàn ,bàn đón tiếp và xếp bàn ghế theo yêu cầu.
                    </p>
                    <p className='px-3'>- Khách sạn sẽ có những mức giá ưu đãi cho từng Hội nghị có sử dụng nhiều dịch vụ kèm theo </p>
                </div>
                <div className='row align-items-center py-2'>
                    <u className='fw-bold py-2'>Các dịch vụ bổ sung: </u>
                    <p className='px-3'>- Đèn chiếu: VND 700.000/ngày - VND 500.000/buổi</p>
                    <p className='px-3'>- Teabreak giữa giờ: VND 30.000đ/xuất/buổi trở lên </p>
                    <p className='px-3'>- Băng rôn in Hiflex treo trước Khách sạn chào đón Khách: VND 500.000/bảng (5m * 0.8m) </p>
                    <p className='px-3'>- Backdrop in Hiflex treo trong phòng Hội nghị: VND 120.000/m2 </p>
                </div>
                <div className='row d-flex w-100 gallery-about'>
                    <div className='row text-center pt-5'>
                        <h3 className='pb-3'>Một số hình ảnh về Hội nghị & Sự kiện</h3>
                    </div>
                    <div className='col-md-12 col-sm-12 gallery d-flex w-100'>
                        <Row className='m-0 gallery-list switch-right col-12'>
                            <div className='col-md-6 col-sm-12 px-0'>
                                <div className='img-right'>
                                    <Image
                                        src='https://demo2.pavothemes.com/seasona/wp-content/uploads/2022/04/gallery_pic8.jpg'
                                        alt='Image'
                                        preview
                                    />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12 px-0 h-100'>
                                <div className='img-left'>
                                    <Row className='m-0 gallery-height'>
                                        <div className='col-md-6 col-sm-12 px-0 h-100'>
                                            <div className='img-gs'>
                                                <Image
                                                    src='https://demo2.pavothemes.com/seasona/wp-content/uploads/2022/04/gallery_pic5.jpg'
                                                    alt='Image'
                                                    preview
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-sm-12 px-0 h-100'>
                                            <div className='img-ge'>
                                                <Image
                                                    src='https://demo2.pavothemes.com/seasona/wp-content/uploads/2022/04/gallery_pic6.jpg'
                                                    alt='Image'
                                                    preview
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row className='m-0 gallery-height'>
                                        <div className='col-md-12 col-sm-12 px-0 h-100'>
                                            <div className='img-bottom'>
                                                <Image
                                                    src='https://demo2.pavothemes.com/seasona/wp-content/uploads/2022/04/gallery_pic7.jpg'
                                                    alt='Image'
                                                    preview
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </Row>
                    </div>
                    <div className='col-md-12 col-sm-12 gallery d-flex w-100'>
                        <Row className='m-0 gallery-list mt-2 switch-left col-12'>
                            <div className='col-md-6 col-sm-12 px-0 img-big'>
                                <div className='img-left'>
                                    <Image
                                        src='https://demo2.pavothemes.com/seasona/wp-content/uploads/2022/04/gallery_pic8.jpg'
                                        alt='Image'
                                        preview
                                    />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12 px-0 h-100'>
                                <div className='img-group'>
                                    <Row className='m-0 gallery-height'>
                                        <div className='col-md-6 col-sm-12 px-0 h-100'>
                                            <div className='img-right'>
                                                <Image
                                                    src='https://demo2.pavothemes.com/seasona/wp-content/uploads/2022/04/gallery_pic5.jpg'
                                                    alt='Image'
                                                    preview
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-sm-12 px-0 h-100'>
                                            <div className='image-about h-100'>
                                                <Image
                                                    src='https://demo2.pavothemes.com/seasona/wp-content/uploads/2022/04/gallery_pic6.jpg'
                                                    alt='Image'
                                                    preview
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row className='m-0 gallery-height'>
                                        <div className='col-md-12 col-sm-12 px-0 h-100'>
                                            <div className='img-bottom'>
                                                <Image
                                                    src='https://demo2.pavothemes.com/seasona/wp-content/uploads/2022/04/gallery_pic7.jpg'
                                                    alt='Image'
                                                    preview
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
}
