import { Room10Img1, Room12Img1, Room1Img1, Room3Img1 } from 'assets/image/room';
import { gymImg1 } from 'assets/image/services/gym';
import { RestaurantImg1 } from 'assets/image/services/restaurant';
import { spaImg1 } from 'assets/image/services/spa';
import { swimmingImg1 } from 'assets/image/services/swimming';
import { Image } from 'primereact/image';
import { Row } from 'react-bootstrap';
export default function AboutGallery() {
    return (
        <div className='row d-flex w-100 gallery-about'>
            <div className='row text-center pt-5'>
                <h2 className='pb-3'>Gallery</h2>
            </div>
            <div className='col-md-12 col-sm-12 gallery d-flex w-100'>
                <Row className='m-0 gallery-list switch-right col-12'>
                    <div className='col-md-6 col-sm-12 px-0'>
                        <div className='img-right'>
                            <Image src={gymImg1} alt='Image' preview />
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 px-0 h-100'>
                        <div className='img-left'>
                            <Row className='m-0 gallery-height'>
                                <div className='col-md-6 col-sm-12 px-0 h-100'>
                                    <div className='img-gs'>
                                        <Image src={swimmingImg1} alt='Image' preview />
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-12 px-0 h-100'>
                                    <div className='img-ge'>
                                        <Image src={RestaurantImg1} alt='Image' preview />
                                    </div>
                                </div>
                            </Row>
                            <Row className='m-0 gallery-height'>
                                <div className='col-md-12 col-sm-12 px-0 h-100'>
                                    <div className='img-bottom'>
                                        <Image src={spaImg1} alt='Image' preview />
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </Row>
            </div>
            <div className='col-md-12 col-sm-12 gallery d-flex w-100'>
                <Row className='m-0 gallery-list mt-2 switch-left col-12'>
                    <div className='col-md-6 col-sm-12 px-0 img-big'>
                        <div className='img-left'>
                            <Image src={Room10Img1} alt='Image' preview />
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 px-0 h-100'>
                        <div className='img-group'>
                            <Row className='m-0 gallery-height'>
                                <div className='col-md-6 col-sm-12 px-0 h-100'>
                                    <div className='img-right'>
                                        <Image src={Room1Img1} alt='Image' preview />
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-12 px-0 h-100'>
                                    <div className='image-about h-100'>
                                        <Image src={Room12Img1} alt='Image' preview />
                                    </div>
                                </div>
                            </Row>
                            <Row className='m-0 gallery-height'>
                                <div className='col-md-12 col-sm-12 px-0 h-100'>
                                    <div className='img-bottom'>
                                        <Image src={Room3Img1} alt='Image' preview />
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </Row>
            </div>
        </div>
    );
}
