import { authService } from 'app/services';
export const BROADCAST_EVENT = {
    LOGOUT: 'logout'
};
const LOGOUT = 'logout';
const channel = new BroadcastChannel('hotel-broadcast-channel');
export const registerLogout = () => {
    channel.addEventListener('message', (event) => {
        if (event.data === LOGOUT) {
            authService.logout();
        }
    });
};
export const forceLogout = () => {
    channel.postMessage(LOGOUT);
};
export default channel;
