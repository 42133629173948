import { CAROUSEL_URL, GALLERY_REMOVE_IMAGE_URL, GALLERY_URL } from 'app/constants';
import { requestDelete, requestGet } from 'app/utils/request';
import { HttpStatusCode } from 'axios';
const get = async (params) => {
    const res = await requestGet(GALLERY_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: []
        };
    }
};
const getCarousel = async (params) => {
    const res = await requestGet(CAROUSEL_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: []
        };
    }
};
/* Request to create user data from server
 * @param {Object} data store user management data
 */
const removeImage = (data) => requestDelete(GALLERY_REMOVE_IMAGE_URL, { data });
export default { get, removeImage, getCarousel };
