import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'react-bootstrap';
import Map from './common/map';

export default function Contact() {
    const addContact = async () => {
        //TODO
    };
    return (
        <div className='auto-container py-5'>
            <div className='row contact-with'>
                <div className='col-md-6 col-sm-12'>
                    <h5 className='subtitle'>Contact Us</h5>
                    <h2 className='title'>Contact With Us</h2>
                    <h2 className='text-right fw-bold pe-5 me-5 pt-2'>Cordial Hotel</h2>
                    <div className='row w-100'>
                        <div className='icon-box py-4 d-flex border-bottom w-100'>
                            <div className='icons'>
                                <i className='pi pi-phone'></i>
                            </div>
                            <div className='pt-1'>
                                <h4 className='title-sub'>Call Us Now</h4>
                                <p className='description-sub'> (+84) 2363996837 - (+84) 2363996839</p>
                            </div>
                        </div>
                    </div>
                    <div className='row w-100'>
                        <div className='icon-box py-4 d-flex border-bottom w-100'>
                            <div className='icons'>
                                <i className='pi pi-map-marker'></i>
                            </div>
                            <div className='pt-1'>
                                <h4 className='title-sub'>Our Locations 1</h4>
                                <p className='description-sub'>27-29 Loseby, Sơn Trà, Đà Nẵng</p>
                            </div>
                        </div>
                    </div>
                    <div className='row w-100'>
                        <div className='icon-box py-4 d-flex w-100'>
                            <div className='icons'>
                                <i className='pi pi-map-marker'></i>
                            </div>
                            <div className='pt-1'>
                                <h4 className='title-sub'>Our Locations 2</h4>
                                <p className='description-sub'> 30 Loseby, Sơn Trà, Đà Nẵng</p>
                            </div>
                        </div>
                    </div>
                    <div className='row w-100'>
                        <div className='icon-box py-4 d-flex w-100'>
                            <div className='icons'>
                                <i className='pi pi-inbox'></i>
                            </div>
                            <div className='pt-1'>
                                <h4 className='title-sub'>Sent Email</h4>
                                <p className='description-sub'> info@cordialhotel.com.vn</p>
                            </div>
                        </div>
                    </div>
                    <div className='row w-100'>
                        <div className='icon-box pb-4 d-flex w-100'>
                            <div className='pt-1 d-flex'>
                                <h4 className='description-sub pt-2 px-3'>Connect with us</h4>
                                <div className='row'>
                                    <Button variant='social m-1 center-about'>
                                        <span className='icon agoda_b' />
                                    </Button>
                                    <Button variant='social m-1'>
                                        <span className='pi pi-instagram social-about' />
                                    </Button>
                                    <Button variant='social m-1'>
                                        <span className='pi pi-facebook social-about' />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <div className='comments-area border bg-contact p-5'>
                        <h3 className='text-white text-center'> Get In Touch </h3>
                        <div className='row py-3'>
                            <div className='w-100 form-group py-3'>
                                <InputText className='form-control' placeholder='Your Name' />
                            </div>
                            <div className='w-100 form-group py-3'>
                                <InputText className='form-control' placeholder=' Email address' />
                            </div>
                        </div>
                        <div className='row pb-3'>
                            <div className='w-100 form-group'>
                                <InputTextarea className='form-control' placeholder='Message' />
                            </div>
                        </div>
                        <div className='row py-3 px-2'>
                            <Button className='btn-action' variant='default' onClick={addContact}>
                                Send Message
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pb-3 pt-5'>
                <Map />
            </div>
        </div>
    );
}
