import { ROOM_DETAIL_URL, ROOM_RECENT_URL, ROOM_URL, UTILITIES, UTILITIES_URL } from 'app/constants';
import { requestGet } from 'app/utils/request';
import { HttpStatusCode } from 'axios';

/**
 * Handle get list room
 * @param {Object} value search content
 */
const search = async (value) => {
    // init params
    const { searchField = '', selectedCategory } = value;
    const params = {
        searchField,
        selectedCategory
    };
    const res = await requestGet(ROOM_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: []
        };
    }
};
const detail = async (params) => {
    const res = await requestGet(ROOM_DETAIL_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: []
        };
    }
};
const recentRoom = async (params) => {
    const res = await requestGet(ROOM_RECENT_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: []
        };
    }
};
/**
 * Handle get list ROOM
 * @param {Object} value search content
 */
const getListUtilities = async () => {
    // init params
    const params = {
        first: 0,
        searchField: '',
        rows: 1000,
        sortField: 'ID',
        selectedFilter: UTILITIES.map((v) => v.value),
        sortOrder: 'ASC'
    };
    const res = await requestGet(UTILITIES_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload.data;
    } else {
        return {
            data: []
        };
    }
};
export default { search, detail, recentRoom, getListUtilities };
