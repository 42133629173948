export default function UtilityElement() {
    return (
        <div className='auto-container common-back-ground'>
            <div className='text-center sec-title pt-4'>
                <div className='sub-title'>Utility</div>
                <h2>Make Your Stay Memorable</h2>
            </div>
            <div className='row'>
                <div className='col-md-12 d-flex justify-content-center row'>
                    <div className='py-4 d-block text-center px-5 col-md-3 col-sm-12'>
                        <div className='d-flex justify-content-center'>
                            <div className='icon-ser d-flex align-items-center justify-content-center'>
                                <span className='icon restaurant'></span>
                            </div>
                        </div>
                        <div className='p-2 mt-2'>
                            <h3 className='mb-3 fs-xl'>Restaurant</h3>
                        </div>
                    </div>
                    <div className='py-4 d-block text-center px-5 col-md-3 col-sm-12'>
                        <div className='d-flex justify-content-center'>
                            <div className='icon-ser d-flex align-items-center justify-content-center'>
                                <span className='icon spa'></span>
                            </div>
                        </div>
                        <div className='p-2 mt-2'>
                            <h3 className='mb-3 fs-xl'>Spa</h3>
                        </div>
                    </div>
                    <div className='py-4 d-block text-center px-5 col-md-3 col-sm-12'>
                        <div className='d-flex justify-content-center'>
                            <div className='icon-ser d-flex align-items-center justify-content-center'>
                                <span className='icon bar'></span>
                            </div>
                        </div>
                        <div className='p-2 mt-2'>
                            <h3 className='mb-3 fs-xl'>Souvenir</h3>
                        </div>
                    </div>
                    <div className='py-4 d-block text-center px-5 col-md-3 col-sm-12'>
                        <div className='d-flex justify-content-center'>
                            <div className='icon-ser d-flex align-items-center justify-content-center'>
                                <span className='icon motorcycle'></span>
                            </div>
                        </div>
                        <div className='p-2 mt-2'>
                            <h3 className='mb-3 fs-xl'>Rent a Motorcycle</h3>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 d-flex justify-content-center row'>
                    <div className='py-4 d-block text-center px-5 col-md-3 col-sm-12'>
                        <div className='d-flex justify-content-center'>
                            <div className='icon-ser d-flex align-items-center justify-content-center'>
                                <span className='icon gym'></span>
                            </div>
                        </div>
                        <div className='p-2 mt-2'>
                            <h3 className='mb-3 fs-xl'>Gym</h3>
                        </div>
                    </div>
                    <div className='py-4 d-block text-center px-5 col-md-3 col-sm-6'>
                        <div className='d-flex justify-content-center'>
                            <div className='icon-ser d-flex align-items-center justify-content-center'>
                                <span className='icon swimming'></span>
                            </div>
                        </div>
                        <div className='p-2 mt-2'>
                            <h3 className='mb-3 fs-xl'>Swimming Pool</h3>
                        </div>
                    </div>
                    <div className='py-4 d-block text-center px-5 col-md-3 col-sm-6'>
                        <div className='d-flex justify-content-center'>
                            <div className='icon-ser d-flex align-items-center justify-content-center'>
                                <span className='icon laundry'></span>
                            </div>
                        </div>
                        <div className='p-2 mt-2'>
                            <h3 className='mb-3 fs-xl'>Laundry</h3>
                        </div>
                    </div>
                    <div className='py-4 d-block text-center px-5 col-md-3 col-sm-6'>
                        <div className='d-flex justify-content-center'>
                            <div className='icon-ser d-flex align-items-center justify-content-center'>
                                <span className='icon wifi'></span>
                            </div>
                        </div>
                        <div className='p-2 mt-2'>
                            <h3 className='mb-3 fs-xl'>Free wifi Avaliable</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
