export const SERVICE = '/services';
export const BLOG = '/blogs';
export const BLOG_DETAIL = '/blog-detail';
export const GALLERY = '/gallery';
export const ROOM = '/rooms';
export const ROOM_DETAIL = '/room-detail/*';
export const CONTACT = '/contact';
export const MEETING_EVENT = '/meeting-event';
export const TOUR_DETAIL = '/tour-detail';
export const TOUR = '/tours';
export const SWIMMING = '/swimming-pool';
export const SPA = '/spa-message';
export const GYM = '/gym-fitness';
export const ABOUT_US = '/about';
export const ABOUT_CORDIAL = '/cordial-hotel';
export const ABOUT_CORDIAL_GRAND = '/cordial-grand-hotel';
export const BASE_NAME = '/';
export const BAR = '/cabanon-restaurant';
export const COFFEE = '/coffee';
export const RESTAURANT = '/restaurant';
export const ID = ':id';
export const ROUTER_SLIDER = [RESTAURANT, COFFEE, BAR, SPA, SWIMMING, GYM];
export const PERMISSION = {
    1: [],
    2: []
};
