import Avatar from 'assets/image/image_avatar.png';
import Left from 'assets/image/left.png';
import Right from 'assets/image/right.png';
import { Carousel } from 'primereact/carousel';
import { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars, prettier/prettier
import { Cheap1, Cheap2, Cheap3, Cheap4, Cheap5, Cheap6, Cheap7, Cheap8 } from 'assets/image/home';

export default function CustomerReview() {
    const [products, setProducts] = useState([]);
    const CheapMock = [
        {
            img: Cheap1,
            id: '1000',
            code: 'f230fh0g3',
            name: 'Bamboo Watch',
            description: 'Product Description',
            image: 'bamboo-watch.jpg',
            price: 65,
            category: 'Accessories',
            quantity: 24,
            inventoryStatus: 'INSTOCK',
            rating: 5
        },
        {
            img: Cheap2,
            id: '1001',
            code: 'nvklal433',
            name: 'Black Watch',
            description: 'Product Description',
            image: 'black-watch.jpg',
            price: 72,
            category: 'Accessories',
            quantity: 61,
            inventoryStatus: 'INSTOCK',
            rating: 4
        },
        {
            img: Cheap3,
            id: '1002',
            code: 'zz21cz3c1',
            name: 'Blue Band',
            description: 'Product Description',
            image: 'blue-band.jpg',
            price: 79,
            category: 'Fitness',
            quantity: 2,
            inventoryStatus: 'LOWSTOCK',
            rating: 3
        },
        {
            img: Cheap4,
            id: '1003',
            code: '244wgerg2',
            name: 'Blue T-Shirt',
            description: 'Product Description',
            image: 'blue-t-shirt.jpg',
            price: 29,
            category: 'Clothing',
            quantity: 25,
            inventoryStatus: 'INSTOCK',
            rating: 5
        },
        {
            img: Cheap5,
            id: '1004',
            code: 'h456wer53',
            name: 'Bracelet',
            description: 'Product Description',
            image: 'bracelet.jpg',
            price: 15,
            category: 'Accessories',
            quantity: 73,
            inventoryStatus: 'INSTOCK',
            rating: 4
        },
        {
            img: Cheap6,
            id: '1005',
            code: 'av2231fwg',
            name: 'Brown Purse',
            description: 'Product Description',
            image: 'brown-purse.jpg',
            price: 120,
            category: 'Accessories',
            quantity: 0,
            inventoryStatus: 'OUTOFSTOCK',
            rating: 4
        },
        {
            img: Cheap7,
            id: '1006',
            code: 'bib36pfvm',
            name: 'Chakra Bracelet',
            description: 'Product Description',
            image: 'chakra-bracelet.jpg',
            price: 32,
            category: 'Accessories',
            quantity: 5,
            inventoryStatus: 'LOWSTOCK',
            rating: 3
        },
        {
            img: Cheap8,
            id: '1007',
            code: 'mbvjkgip5',
            name: 'Galaxy Earrings',
            description: 'Product Description',
            image: 'galaxy-earrings.jpg',
            price: 34,
            category: 'Accessories',
            quantity: 23,
            inventoryStatus: 'INSTOCK',
            rating: 5
        }
    ];
    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    useEffect(() => {
        setProducts(CheapMock);
    }, []);

    const productTemplate = () => {
        return (
            <div className='bd-testimonial-4 mx-1'>
                <div className='bd-testimonial-4__content'>
                    <div className='bd-testimonial-4__quote'>
                        <img src={Right} />
                    </div>
                    <p>
                        The best hotel I’ve ever been privileged enough to stay at. Gorgeous building, and it only gets more breath taking.
                    </p>
                    <div className='bd-testimonial-4__rating d-flex justify-content-center'>
                        <i className='pi pi-star-fill'></i>
                        <i className='pi pi-star-fill'></i>
                        <i className='pi pi-star-fill'></i>
                        <i className='pi pi-star-fill'></i>
                    </div>
                    <div className='bd-testimonial-4__quote-2'>
                        <img src={Left} />
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='bd-testimonial-4__thumb'>
                            <img
                                alt='image not found'
                                loading='lazy'
                                width='300'
                                height='300'
                                decoding='async'
                                data-nimg='1'
                                src={Avatar}
                                style={{ color: 'transparent', width: '100%', height: '100%' }}
                            />
                        </div>
                        <div className='bd-testimonial-4__client'>
                            <h5 className='bd-testimonial-4__client-name'>Jhon Wick</h5>
                            <span className='bd-testimonial-4__client-position'>Moscow / Russia</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='auto-container common-back-ground'>
            <div className='text-center sec-title pt-4'>
                <div className='sub-title'>Customer Review</div>
                <h2 className='sec-title'>Our Customers Say</h2>
            </div>
            <div className='card news-block'>
                <Carousel
                    value={products}
                    numVisible={3}
                    numScroll={1}
                    responsiveOptions={responsiveOptions}
                    itemTemplate={productTemplate}
                    circular
                    autoplayInterval={5000}
                />
            </div>
        </div>
    );
}
