import environments from 'environments';
import _ from 'lodash';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
/**
 * Function to set cookie
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Number} days expire day for cookie
 */
export function setCookie(name, value, days = 1) {
    let expires = '';

    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();

    document.cookie = name + '=' + (value || '') + expires + '; path=' + (environments.BASE_NAME || '/');
}
/**
 * Function to delete cookie
 * @param {*} name cookie name
 */
export function deleteCookie(name) {
    document.cookie = name + '=; Path=' + (environments.BASE_NAME || '/') + '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
/**
 * Function to get cookie
 * @param {*} name cookie name
 */
export function getCookie(name) {
    const nameEQ = name + '=';
    const cookieArr = document.cookie.split(';');
    const value = cookieArr
        .find((item) => item.includes(nameEQ))
        .split('=')[1]
        .trim();
    return value;
}
/**
 * parse query string to object
 * @param {String} queryString query string
 *
 * @returns {Object}
 */
export function parseQueryParam(queryString) {
    const query = new URLSearchParams(queryString);
    const params = {};
    for (const [key, value] of query.entries()) {
        params[key] = value;
    }
    return params;
}
/**
 * generate url
 * @param {*} path
 * @param {*} params
 * @param {*} base
 * @returns
 */
export function generateURL(path, params, base = environments.API_ENDPOINT) {
    const url = new URL(base + path, base);
    url.search = new URLSearchParams(params);
    return url.toString();
}
/**
 *
 * @param {*} route
 * @param {*} screen
 * @param {*} params
 * @returns
 */
export function generateScreenPath(route, screen, params) {
    const screens = _.isArray(screen) ? screen.join('/') : screen;
    return generatePath(route + '/' + screens, params);
}
/**
 *
 * @returns
 */
export function useRedirect() {
    const navigate = useNavigate();
    return useCallback((route, screen, params = {}, state = {}) => {
        navigate(generateScreenPath(route, screen, params), { relative: 'route', replace: false, state }), [navigate];
    });
}
