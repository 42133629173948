import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isEditing: false,
    showLeave: false,
    reFetch: false,
    callback: false
};
export const confirmSave = createSlice({
    name: 'confirmSave',
    initialState,
    reducers: {
        editAction: (state, action) => {
            state.isEditing = action.payload;
        },
        leaveAction: (state, action) => {
            state.showLeave = action.payload.showLeave;
            state.callback = action.payload.callback;
        },
        refetchAction: (state, action) => {
            state.reFetch = action.payload;
        }
    }
});
export const { leaveAction, refetchAction, editAction } = confirmSave.actions;
export default confirmSave.reducer;
