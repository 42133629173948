import { SERVICE_DETAIL_URL, SERVICE_URL } from 'app/constants';
import { requestGet } from 'app/utils/request';
import { HttpStatusCode } from 'axios';
const get = async (params) => {
    const res = await requestGet(SERVICE_DETAIL_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: []
        };
    }
};
const search = async (params) => {
    const res = await requestGet(SERVICE_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: []
        };
    }
};
export default { get, search };
