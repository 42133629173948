import store from 'app/store';
import { preloaderAction, preloadText } from 'app/store/common';
export default {
    preloader: {
        show: () => store.dispatch(preloaderAction(true)),
        hide: () => store.dispatch(preloaderAction(false)),
        text: (data) => store.dispatch(preloadText(data))
    },
    get toast() {
        return global.toast;
    },
    get modal() {
        return global.modal;
    },
    get confirmReplace() {
        return global.confirmReplace;
    },
    get message() {
        return global.messages;
    }
};
