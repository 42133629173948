//#region  import
// page
import { ROUTER } from 'app/constants';
import { LoaderDataError, MasterLayout } from 'app/views/core';
// core
import {
    AboutUs,
    BlogDetail,
    Blogs,
    CabanonRestaurant,
    Contact,
    Events,
    Galleries,
    Gym,
    Homepage,
    NotFound,
    Restaurant,
    RoomDetail,
    Rooms,
    Services,
    Spa,
    Swimming,
    TourDetail,
    Tours
} from 'app/views/pages';
// environment
import env from 'environments';
import { useMemo } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

//#end region

export default function App() {
    const router = useMemo(
        () =>
            createBrowserRouter(
                createRoutesFromElements(
                    <Route errorElement={LoaderDataError}>
                        <Route element={<MasterLayout />}>
                            <Route path={ROUTER.BASE_NAME} key={ROUTER.BASE_NAME} element={<Homepage />} />
                            <Route path={ROUTER.GYM} element={<Gym />} />
                            <Route path={ROUTER.SERVICE} element={<Services />} />
                            <Route path={ROUTER.BLOG} element={<Blogs />} />
                            <Route path={ROUTER.BLOG_DETAIL} element={<BlogDetail />} />
                            <Route path={ROUTER.ROOM} element={<Rooms />} />
                            <Route path={ROUTER.ROOM_DETAIL} element={<RoomDetail />} />
                            <Route path={ROUTER.GALLERY} element={<Galleries />} />
                            <Route path={ROUTER.CONTACT} element={<Contact />} />
                            <Route path={ROUTER.MEETING_EVENT} element={<Events />} />
                            <Route path={ROUTER.ABOUT_US} element={<AboutUs />} />
                            <Route path={ROUTER.TOUR_DETAIL} element={<TourDetail />} />
                            <Route path={ROUTER.TOUR} element={<Tours />} />
                            <Route path={ROUTER.SPA} element={<Spa />} />
                            <Route path={ROUTER.SWIMMING} element={<Swimming />} />
                            <Route path={ROUTER.BAR} element={<CabanonRestaurant />} />
                            <Route path={ROUTER.RESTAURANT} element={<Restaurant />} />
                            <Route path='*' element={<NotFound />} />
                        </Route>
                    </Route>
                ),
                {
                    basename: env.BASE_NAME
                }
            ),
        []
    );
    return (
        <>
            <RouterProvider router={router} />
        </>
    );
}
