import axios from 'axios';
const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: { 'Cache-Control': 'no-cache', Expires: -1, Pragma: 'no-cache' }
});

// Common handle request
const handleError = async (request, ...rest) => {
    try {
        return await request(...rest);
    } catch (error) {
        if (error.response) {
            return error.response;
        }
        // The request was made but no response was received
        return { ...error, status: false };
    }
};

/**
 * Method get request
 * @param {String} url the server URL that will be used for the request
 * @param {import('axios').AxiosRequestConfig} config store request config
 *
 * @returns {import('axios').AxiosResponse}
 */
const requestGet = async (url, config = {}) => handleError(axiosInstance.get, url, config);

/**
 * Method head request
 * @param {String} url the server URL that will be used for the request
 * @param {import('axios').AxiosRequestConfig} config store request config
 *
 * @returns {import('axios').AxiosResponse}
 */
const requestHead = async (url, config = {}) => handleError(axiosInstance.head, url, config);

/**
 * Method post request
 * @param {String} url the server URL that will be used for the request
 * @param {*} model store data to be sent as the request body
 * @param {import('axios').AxiosRequestConfig} config store request config
 *
 * @returns {import('axios').AxiosResponse}
 */
const requestPost = async (url, model, config = {}) => handleError(axiosInstance.post, url, model, config);

/**
 * Method put request
 * @param {String} url the server URL that will be used for the request
 * @param {*} model store data to be sent as the request body
 * @param {import('axios').AxiosRequestConfig} config store request config
 *
 * @returns {import('axios').AxiosResponse}
 */
const requestPut = async (url, model, config = {}) => handleError(axiosInstance.put, url, model, config);

/**
 * Method delete request
 * @param {String} url the server URL that will be used for the request
 * @param {import('axios').AxiosRequestConfig} config store request config
 *
 * @returns {import('axios').AxiosResponse}
 */
const requestDelete = async (url, config = {}) => handleError(axiosInstance.delete, url, config);

export { requestGet, requestHead, requestPost, requestPut, requestDelete, axiosInstance };
