import { configureStore } from '@reduxjs/toolkit';
import authentication from 'app/store/authentication';
import common from 'app/store/common';
import confirmSave from 'app/store/confirm-save';
import sidebar from 'app/store/sidebar';
export default configureStore({
    reducer: {
        authentication,
        common,
        confirmSave,
        sidebar
    }
});
