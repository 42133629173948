import { Carousel } from 'primereact/carousel';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars, prettier/prettier
import { ROUTER } from 'app/constants';
import { Cheap1, Cheap2, Cheap3, Cheap4, Cheap5, Cheap6, Cheap7, Cheap8 } from 'assets/image/home';
import { Image } from 'primereact/image';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function CheapTours() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const CheapMock = [
        {
            img: Cheap1,
            id: '1000',
            code: 'f230fh0g3',
            name: 'Bamboo Watch',
            description: 'Product Description',
            image: 'bamboo-watch.jpg',
            price: 65,
            category: 'Accessories',
            quantity: 24,
            inventoryStatus: 'INSTOCK',
            rating: 5
        },
        {
            img: Cheap2,
            id: '1001',
            code: 'nvklal433',
            name: 'Black Watch',
            description: 'Product Description',
            image: 'black-watch.jpg',
            price: 72,
            category: 'Accessories',
            quantity: 61,
            inventoryStatus: 'INSTOCK',
            rating: 4
        },
        {
            img: Cheap3,
            id: '1002',
            code: 'zz21cz3c1',
            name: 'Blue Band',
            description: 'Product Description',
            image: 'blue-band.jpg',
            price: 79,
            category: 'Fitness',
            quantity: 2,
            inventoryStatus: 'LOWSTOCK',
            rating: 3
        },
        {
            img: Cheap4,
            id: '1003',
            code: '244wgerg2',
            name: 'Blue T-Shirt',
            description: 'Product Description',
            image: 'blue-t-shirt.jpg',
            price: 29,
            category: 'Clothing',
            quantity: 25,
            inventoryStatus: 'INSTOCK',
            rating: 5
        },
        {
            img: Cheap5,
            id: '1004',
            code: 'h456wer53',
            name: 'Bracelet',
            description: 'Product Description',
            image: 'bracelet.jpg',
            price: 15,
            category: 'Accessories',
            quantity: 73,
            inventoryStatus: 'INSTOCK',
            rating: 4
        },
        {
            img: Cheap6,
            id: '1005',
            code: 'av2231fwg',
            name: 'Brown Purse',
            description: 'Product Description',
            image: 'brown-purse.jpg',
            price: 120,
            category: 'Accessories',
            quantity: 0,
            inventoryStatus: 'OUTOFSTOCK',
            rating: 4
        },
        {
            img: Cheap7,
            id: '1006',
            code: 'bib36pfvm',
            name: 'Chakra Bracelet',
            description: 'Product Description',
            image: 'chakra-bracelet.jpg',
            price: 32,
            category: 'Accessories',
            quantity: 5,
            inventoryStatus: 'LOWSTOCK',
            rating: 3
        },
        {
            img: Cheap8,
            id: '1007',
            code: 'mbvjkgip5',
            name: 'Galaxy Earrings',
            description: 'Product Description',
            image: 'galaxy-earrings.jpg',
            price: 34,
            category: 'Accessories',
            quantity: 23,
            inventoryStatus: 'INSTOCK',
            rating: 5
        }
    ];
    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    useEffect(() => {
        setProducts(CheapMock);
    }, []);
    const productTemplate = (item) => {
        return (
            <div className='px-1 py-1 events'>
                <div className='mx-2'>
                    <div>
                        <Image src={item.img} alt='' preview />
                    </div>
                    <div className='events-body px-3'>
                        <div className='entity-header'>
                            <h2 className='event-title pt-3'>
                                <a href='/tour-detail'>Nemo Enim Ipsam Voluptatem</a>
                            </h2>
                        </div>
                        <div className='pt-2'>
                            <p className='entity-body'>
                                Aliquam erat volutpat. Duis ac turpis. Donec sit amet eros. Lorem ipsum dolor. Mauris fermentum dictum
                                magna. Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget, elementum vel, cursus eleifend, elit.
                            </p>
                        </div>
                        <div className='row mx-0, px-3 pb-5'>
                            <Button className='btn-action-sm' variant='default' onClick={() => navigate(ROUTER.TOUR_DETAIL)}>
                                {t('BTN_MORE_DETAIL')}
                            </Button>
                            <Button variant='social-1'>
                                <span className='pi pi-instagram' />
                            </Button>
                            <Button variant='social-1'>
                                <span className='pi pi-facebook' />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='auto-container common-back-ground'>
            <div className='text-center sec-title pt-4'>
                <div className='sub-title'>Cheap Tours</div>
                <h2 className='sec-title'>Discover Affordable Tours</h2>
            </div>
            <div className='card news-block'>
                <Carousel
                    value={products}
                    numVisible={3}
                    numScroll={1}
                    responsiveOptions={responsiveOptions}
                    itemTemplate={productTemplate}
                    circular
                    autoplayInterval={5000}
                />
            </div>
        </div>
    );
}
