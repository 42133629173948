import { ImgAboutUs } from 'assets/image/header';
import { Image } from 'primereact/image';
import { Button } from 'react-bootstrap';
import AboutGallery from './gallery';
export default function AboutUs() {
    return (
        <div className='about auto-container py-5'>
            <div className='row d-flex align-items-center justify-content-center mb-5'>
                <div className='col-md-5 col-sm-12'>
                    <Image src={ImgAboutUs} alt='' imageClassName='w-100' />
                </div>
                <div className='col-md-7 col-sm-12'>
                    <div className='content-about'>
                        <div className='fs-lg mb-2'>Welcome to Cordial Hotel</div>
                        <h2 className='about-title pb-4'>Welcome To Our Hotel</h2>
                        <div className='text-two'>
                            <p>Kính gửi Quý khách hàng</p>
                            <p>
                                Lời đầu tiên, thay mặt toàn thể Khách sạn CORDIAL HOTEL ĐÀ NẴNG xin gửi đến Quý khách lời chúc sức khỏe và
                                lời chào trân trọng.
                            </p>
                            <p>
                                CORDIAL HOTEL ĐÀ NẴNG là khách sạn nghỉ dưỡng 4 sao cao cấp tọa lạc trên cung đường Loseby cách 150m bờ biển
                                trải dài đầy thơ mộng. Từ Khách sạn Quý khách chỉ mất 5 phút đi bộ ra biển, cách trung tâm thành phố 4,5 km,
                                cách sân bay Đà Nẵng 6,6 km, cách các danh lam thắng cảnh như Bà Nà Hills, bán đảo Sơn Trà, phố cổ Hội An
                                khoảng 25 phút đi xe.
                            </p>
                            <p>
                                Nội thất của khách sạn được thiết kế theo phong cách hiện đại, cao cấp và đồng bộ theo tiêu chuẩn quốc tế 4
                                sao. Với quy mô 21 tầng, hệ thống 80 phòng chia thành 7 hạng phòng. Mỗi phòng là không gian riêng ấm cúng,
                                hài hòa và thân thiện như chính ngôi nhà của Quý khách.
                            </p>
                            <p>Với danh hiệu 4 sao, đây là điểm đến lý tưởng cho những ai tìm kiếm sự thoải mái và dịch vụ chất lượng.</p>
                            <p>
                                Ngoài ra, khách hàng còn có thể thưởng thức bữa ăn ngon tại nhà hàng của khách sạn hoặc thư giãn với đồ uống
                                tại quầy bar. Tất cả những tiện nghi và dịch vụ sang trọng này sẽ giúp du khách có trải nghiệm đáng nhớ tại
                                Đà Nẵng.
                            </p>
                            <p>
                                Tại đây, hệ thống phòng họp, hội nghị sang trọng với 70 chỗ ngồi cùng với trang thiết bị hiện đại sẽ giúp
                                Quý khách có thể tổ chức các hội nghị, hội thảo cấp cao. Nhà hàng với sức chứa 100 chỗ ngồi cùng với thực
                                đơn Âu, Á phong phú bởi chính tay các đầu bếp giàu kinh nghiệm do CORDIAL HOTEL ĐÀ NẴNG tuyển chọn.
                            </p>
                            <p>
                                Đội ngũ nhân viên lành nghề, phục vụ tận tình chu đáo, thân thiện tại CORDIAL HOTEL ĐÀ NẴNG luôn mang đến
                                chất lượng dịch vụ tốt nhất và chắc chắn sẽ làm cho Quý khách hài lòng. CORDIAL HOTEL ĐÀ NẴNG với phương
                                châm phục vụ ân cần, niềm nở, chu đáo và chuyên nghiệp của đội ngũ nhân viên giàu kinh nghiệm. Khách sạn hứa
                                hẹn sẽ là điểm dừng chân lý tưởng cho các du Khách, doanh nhân trong và ngoài nước, giúp Quý khách cảm nhận
                                được sự thỏa mái hoàn toàn sau một chuyến lưu nghỉ đầy ý nghĩa tại “Thành phố biển đáng sống”. Chúng tôi rất
                                mong nhận được sự quan tâm và hợp tác của Quý khách. Chúng tôi chúc quý khách một kỳ nghỉ vui vẻ, thoải mái
                                và hi vọng luôn được đón tiếp quý khách tại đây.{' '}
                            </p>
                            <p className='mb-1'>Chân thành cảm ơn Quý khách hàng</p>
                            <p className='fw-bold mb-0'>ORDIAL HOTEL ĐÀ Nẵng</p>
                        </div>
                        <div className='row pt-2'>
                            <Button variant='social'>
                                <span className='pi pi-twitter' />
                            </Button>
                            <Button variant='social'>
                                <span className='pi pi-instagram' />
                            </Button>
                            <Button variant='social'>
                                <span className='pi pi-facebook' />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row d-flex w-100 px-5'>
                <div className='row section_title text-center pt-5'>
                    <h2 className='title_w'>Facilities</h2>
                    <p>Who are in extremely love with eco friendly system.</p>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon restaurant'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>Restaurant</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon spa'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>Spa</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon bar'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>Souvenir</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon motorcycle'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>Rent a Motorcycle</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon gym'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>Gym</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon swimming'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>Swimming Pool</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon laundry'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>Laundry</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 d-flex'>
                        <div className='py-4 d-block text-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='icon-ser d-flex align-items-center justify-content-center'>
                                    <span className='icon wifi'></span>
                                </div>
                            </div>
                            <div className='p-2 mt-2'>
                                <h3 className='mb-3 fs-xl'>Free wifi Avaliable</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AboutGallery />
        </div>
    );
}
