import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    preloader: true,
    preloadText: '',
    editing: false,
    editingMsg: 'M015',
    confirming: false
};
export const common = createSlice({
    name: 'common',
    initialState,
    reducers: {
        preloaderAction: (state, action) => {
            state.preloader = action.payload;
            if (!action.payload) {
                state.preloadText = '';
            }
        },
        preloadText: (state, action) => {
            state.preloadText = action.payload;
        },
        setEditing: (state, action) => {
            state.editing = action.payload;
            if (!action.payload) {
                state.editing = 'M0015';
            }
        }
    }
});
export const { preloaderAction, preloadText, setEditing } = common.actions;
export default common.reducer;
