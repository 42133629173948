import { IMAGE_TYPE, ROUTER } from 'app/constants';
import { ROUTER_SLIDER } from 'app/constants/routes';
import { commonService, galleryServiceService } from 'app/services';
import { Footer, Header } from 'app/views/core';
import environments from 'environments';
import { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Slideshow from './header/slide-show';

export default function MasterLayout() {
    const [image, setImage] = useState();
    const [isMainPage, setIsMainPage] = useState(false);
    const getListImage = async () => {
        // const isMainPage = window.location.pathname == ROUTER.BASE_NAME;
        const flag = !ROUTER_SLIDER.includes(window.location.pathname);
        const data = await galleryServiceService.getCarousel();
        let res = data.filter((v) => v.Type === IMAGE_TYPE.CAROUSEL);
        switch (window.location.pathname) {
            case ROUTER.RESTAURANT:
                console.log('');
                setImage(`${environments.PUBLIC_IMAGE}/image/home/header/${data.filter((v) => v.Type === IMAGE_TYPE.RESTAURANT)[0].Image}`);
                break;
            case ROUTER.BAR:
                setImage(
                    `${environments.PUBLIC_IMAGE}/image/home/header/${data.filter((v) => v.Type === IMAGE_TYPE.CABANON_RESTAURANT)[0].Image}`
                );
                break;
            default:
                res = res.map((v) => `${environments.PUBLIC_IMAGE}/image/home/carousel/${v.Image}`);
                setImage(res);
                break;
        }
        setIsMainPage(flag);
    };
    useEffect(() => {
        commonService.preloader.hide();
        getListImage();
    }, []);
    return (
        <Col className='page'>
            <Header />
            <Slideshow isMainPage={isMainPage} imanges={image} />
            <Outlet />
            <Footer />
        </Col>
    );
}
