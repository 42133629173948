import About from './about';
import CheapTours from './cheap-tours';
import CustomerReview from './customer-review';
import LocationElement from './location';
import UtilityElement from './utility';
export default function Homepage() {
    return (
        <div className='home-auto-container my-5'>
            {About()}
            {UtilityElement()}
            {CustomerReview()}
            {CheapTours()}
            {LocationElement()}
        </div>
    );
}
