import {
    ASYNC_TASK_EVENT,
    ConnectStatus,
    SOCKET_CONNECT,
    SOCKET_CONNECT_ERROR,
    SOCKET_DISCONNECT,
    SOCKET_RECONNECT,
    SOCKET_RECONNECT_FAILED
} from 'app/constants';
import store from 'app/store';
import { connectionAction } from 'app/store/authentication';
import environments from 'environments';
/* eslint-disable */
import Worker from 'worker-loader!./worker.js';
/**
 * Socket service
 */
class Socket {
    constructor() {
        this.handler = {};
        this.tempHandler = {};
        this.id = null;
        this.connected = false;
        this.disconnected = true;
        this.userId = null;
        this.executeName = null;
        this.showDialog = true;
        this._initial();
    }
    /**
     * Initial worker
     */
    _initial() {
        this.worker = new Worker(new URL('./worker.js', import.meta.url));
        this.worker.onmessage = this.onMessageHandler;
        this.on(SOCKET_CONNECT, this._socketConnect);
        this.on(SOCKET_DISCONNECT, this._socketDisconnect);
        this.on(SOCKET_RECONNECT, this._socketReconnect);
        this.on(SOCKET_RECONNECT_FAILED, this._socketReconnectFailed);
    }
    /**
     * Handle message
     * @param {Object} event
     */
    onMessageHandler = (event) => {
        this.worker.dispatchEvent(new CustomEvent(event.data[0], { detail: event.data[1] }));
    };
    /**
     * Set post message is open
     */
    open() {
        if (this.connected) {
            return ConnectStatus.Success;
        }
        if (this.promise) {
            return this.promise;
        }
        this.promise = new Promise((rs) => {
            this.listenConnectErrorOnce((data) => {
                if (data) {
                    rs(ConnectStatus.Success);
                }
            });
            this.listenConnectFailOnce(() => rs(ConnectStatus.Fail));
            this.listenConnectErrorOnce(() => rs(ConnectStatus.Error));
        });
        this.worker.postMessage(['open', environments.SOCKET_ENDPOINT + environments.SOCKET_PATH, environments.SOCKET_PATH]);
        return this.promise;
    }
    /**
     * Set post message is close
     */
    close() {
        this.showDialog = showDialog;
        this.worker.postMessage(['close']);
    }
    /**
     * To execute action
     * @param {String} name action name
     * @function handler callback handler event
     * @param {Boolean} keep
     */
    on(name, handler, once = false) {
        this.worker.addEventListener(name, handler, { once });
        return this;
    }
    onWithTimeout(name, handler, timeout, timeoutData, once = false) {
        const timeoutID = setTimeout(() => this.trigger(name, timeoutData), timeout);
        return this.on(
            name,
            (data) => {
                handler(data);
                clearTimeout(timeoutID);
            },
            once
        );
    }
    once(name, handler) {
        this.on(name, handler, true);
    }
    onceWithTimeout(name, handler, timeout, data) {
        this.onWithTimeout(name, handler, timeout, data, true);
    }
    /**
     * To remove listener
     * @param {String} name action name
     * @function handler callback handler event
     */
    removeListener(name, handler) {
        this.worker.removeEventListener(name, handler);
        return this;
    }
    /**
     * To set id , status , cookie when connect socket
     * @param {String} data store id
     */
    _socketConnect = ({ detail }) => {
        console.log('connected');
        this.id = detail;
        this.connected = true;
        this.disconnected = false;
        store.dispatch(connectionAction(true));
    };
    /**
     * To set id , status , cookie when disconnect socket
     */
    _socketDisconnect = () => {
        console.log('disconnect');
        store.dispatch(connectionAction(false));
        this.connected = false;
        this.disconnected = true;
    };
    /**
     * Request to reconnect socket
     */
    _socketReconnect = () => {
        console.log('reconnected');
    };
    /**
     * To set socket status , show error message
     */
    _socketReconnectFailed = () => {
        this.connected = false;
        this.disconnected = true;
    };
    /**
     * Get socket instance
     */
    getInstance() {
        if (!this.instance && this.instance.disconnect) {
            this.instance = this.instance.open();
        }
        return this.instance;
    }
    /**
     * Add event listener
     * @param {String} event event name
     * @param {Function} handler callback handler event
     */
    listen(event, handler) {
        this.on(event, handler);
    }
    /**
     * Add event listen async task once
     * @param {Function} handler callback handler event
     */
    listenAsyncTaskOnce(handler) {
        this.once(ASYNC_TASK_EVENT, handler);
    }
    listenConnect(handler) {
        this.on(SOCKET_CONNECT, handler);
    }
    listenConnectFail(handler) {
        this.on(SOCKET_RECONNECT_FAILED, handler);
    }
    listenConnectOnce(handler) {
        this.once(SOCKET_CONNECT, handler);
    }
    listenConnectFailOnce(handler) {
        this.once(SOCKET_RECONNECT_FAILED, handler);
    }
    listenDisconnectOnce(handler) {
        this.once(SOCKET_DISCONNECT, handler);
    }
    /**
     * To trigger message
     * @param {String} event event name
     * @param {Object} param info status
     */
    trigger = (event, param) => {
        this.worker.postMessage(['onevent', event, param]);
    };

    listenConnectErrorOnce(handler) {
        this.once(SOCKET_CONNECT_ERROR, handler);
    }

    /**
     * Add event listen async task
     * @param {Function} handler callback handler event
     */
    listenConnectError(handler) {
        this.on(SOCKET_CONNECT_ERROR, handler);
    }
}

export default new Socket();
