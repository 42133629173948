import { GROUP, ROUTER } from 'app/constants';
import { commonService, serviceService } from 'app/services';
import UtilityElement from 'app/views/pages/home/utility';
import { gymImg3 } from 'assets/image/services/gym';
import { spaImg2 } from 'assets/image/services/spa';
import { swimmingImg2 } from 'assets/image/services/swimming';
import { useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
export default function Services() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    /**
     * fetch data from db with ID
     * @returns
     */
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await serviceService.search({ group: GROUP.SERVICE });
        setData(data);
        commonService.preloader.hide();
        return true;
    };
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
    }, []);
    return (
        <div>
            <div className='auto-container home-auto-container'>
                <div className='title py-5'></div>
                <div className='services-block-wrapper'>
                    <div className='service-block-three'>
                        <div className='row'>
                            <div className='col-lg-7 image-column'>
                                <div className='image'>
                                    <Image src={gymImg3} alt='' preview />
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='content sec-title'>
                                    <div className='sub-title'>Service</div>
                                    <h2 className='sec-title small mb-30'>Gym & Fitness</h2>
                                    <div className='text-two'>
                                        <p className='py-1 mb-0 pt-5'>{data[0]?.Content}</p>
                                    </div>
                                    <div className='time pt-2'>Location: {data[0]?.Location}</div>
                                    <div className='time'>Operating hours: {data[0]?.Operating}</div>
                                    <div className='link-btn'>
                                        <Button className='btn-action' variant='default' onClick={() => navigate(ROUTER.GYM)}>
                                            {t('BTN_MORE_DETAIL')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='service-block-three'>
                        <div className='row left-content'>
                            <div className='col-lg-7 image-column'>
                                <div className='image'>
                                    <Image src={swimmingImg2} alt='' preview />
                                </div>
                            </div>
                            <div className='col-lg-5 content-column'>
                                <div className='inner-column content'>
                                    <div className='sec-title'>
                                        <div className='sub-title'>Service</div>
                                    </div>
                                    <h2 className='sec-title small mb-30'>Swimming Pool</h2>
                                    <div className='text-two text-ellipsis-5line pe-3'>{data[1]?.Content}</div>
                                    <div className='time pt-2'>Location: {data[1]?.Location}</div>
                                    <div className='time'>Operating hours: {data[1]?.Operating}</div>
                                    <div className='link-btn'>
                                        <Button className='btn-action' variant='default' onClick={() => navigate(ROUTER.SWIMMING)}>
                                            {t('BTN_MORE_DETAIL')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='service-block-three'>
                        <div className='row'>
                            <div className='col-lg-7 image-column'>
                                <div className='image'>
                                    <Image src={spaImg2} alt='' preview />
                                </div>
                            </div>
                            <div className='col-lg-5 content-column'>
                                <div className='content'>
                                    <div className='sub-title'>Service</div>
                                    <h2 className='sec-title small mb-30'>Spa & Massage</h2>
                                    <div className='text-two text-ellipsis-5line'>{data[2]?.Content}</div>
                                    <div className='time pt-2'>Location: {data[12]?.Location}</div>
                                    <div className='time'>Operating hours: {data[2]?.Operating}</div>
                                    <div className='link-btn'>
                                        <Button className='btn-action' variant='default' onClick={() => navigate(ROUTER.SPA)}>
                                            {t('BTN_MORE_DETAIL')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {UtilityElement()}
                </div>
            </div>
        </div>
    );
}
