import { Carousel, Image } from 'react-bootstrap';

// eslint-disable-next-line react/prop-types
export default function Slideshow({ isMainPage, imanges }) {
    const renderTemplate = () => {
        const list = [];
        for (const item of imanges) {
            const value = (
                <Carousel.Item>
                    <Image src={item} className='d-inline-block align-top' />
                </Carousel.Item>
            );
            list.push(value);
        }
        return list;
    };
    return isMainPage ? (
        <Carousel data-bs-theme='light'>{renderTemplate()}</Carousel>
    ) : (
        <div
            style={{
                backgroundImage: `url(${imanges})`
            }}
            className='bgSlide d-inline-block align-top'
        />
    );
}
