import { ROUTER } from 'app/constants';
import Logo from 'assets/image/logo.png';
import { Button, Image } from 'react-bootstrap';
export default function Footer() {
    return (
        <div className='d-flex justify-content-center footer'>
            <div className='row wp-75 pt-5 pb-3'>
                <div className='col-md-3 col-sm-12'>
                    <div>
                        <Image src={Logo} className='logo-footer' />
                        <label className='px-3 fp-24 fw-bold'>Cordial Hotel</label>
                    </div>
                    <p className='pt-4'>
                        Welcome to the best five-star deluxe hotel in New York. Hotel elementum sesue the aucan volutpat.
                    </p>
                    <div className='row pb-4'>
                        <Button variant='social m-1 center-about'>
                            <span className='icon agoda' />
                        </Button>
                        <Button variant='social m-1'>
                            <span className='pi pi-instagram social-about' />
                        </Button>
                        <Button variant='social m-1'>
                            <span className='pi pi-facebook social-about' />
                        </Button>
                    </div>
                </div>
                <div className='col-md-3 col-sm-12 ps-4'>
                    <h5 className='ps-2'>Services</h5>
                    <ul className='list-footer'>
                        <li className='pt-4 pb-2'>
                            <a className='ps-2 text-white' href={ROUTER.BASE_NAME}>
                                Home page
                            </a>
                        </li>
                        <li className='py-2'>
                            <a className='ps-2 text-white' href={ROUTER.ABOUT_US}>
                                About us
                            </a>
                        </li>
                        <li className='py-2'>
                            <a className='ps-2 text-white' href={ROUTER.ROOM}>
                                Room and Suites
                            </a>
                        </li>
                        <li className='py-2'>
                            <a className='ps-2 text-white' href={ROUTER.BLOG}>
                                News
                            </a>
                        </li>
                        <li className='py-2'>
                            <a className='ps-2 text-white' href={ROUTER.ABOUT_US}>
                                Special Offers
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='col-md-3 col-sm-12'>
                    <h5 className='ps-2'>Experiences</h5>
                    <ul className='list-footer'>
                        <li className='pt-4 pb-2'>
                            <span className='ps-2'>Dining</span>
                        </li>
                        <li className='py-2'>
                            <span className='ps-2'>Wellness & Spa</span>
                        </li>
                        <li className='py-2'>
                            <span className='ps-2'>Gym</span>
                        </li>
                        <li className='py-2'>
                            <span className='ps-2'>Menu</span>
                        </li>
                        <li className='py-2'>
                            <span className='ps-2'>Local Activies</span>
                        </li>
                    </ul>
                </div>
                <div className='col-md-3 col-sm-12'>
                    <h5>Contact Us</h5>
                    <p className='pt-4'>27-29 Loseby, Sơn Trà, Đà Nẵng</p>
                    <p className='py-1'>30 Loseby, Sơn Trà, Đà Nẵng</p>
                    <p className='py-1'>Tel: (+84) 2363996837</p>
                    <p className='py-1'>Tel: (+84) 2363996839</p>
                    <p className='py-1 word-wrap'>Mail: info@cordialhotel.com.vn</p>
                </div>
                <p className='text-center pt-4'>© Copyright Reserved by MT</p>
            </div>
        </div>
    );
}
