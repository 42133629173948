import { t } from 'i18next';
import { Image } from 'primereact/image';
import { Button } from 'react-bootstrap';
export default function RightBlog() {
    return (
        <div className='col-md-4 col-sm-12'>
            <div className='widget'>
                <div className='recent-post'>
                    <h5 className='widget-title mb-3'>{t('LABEL_RECENT_POST')}</h5>
                    <ul className='d-grid'>
                        <li>
                            <a href='/blog-detail' className='pt-2'>
                                <Image src='https://wp.ditsolution.net/royella-multipurpose/wp-content/uploads/2024/03/blog-4-img2-80x80.jpg' />
                                <span className='text-ellipsis-2line pt-1'>How Booking our Hotel ResortUsing Websites?</span>
                            </a>
                            <div className='text-ellipsis-2line'>March 19, 2024</div>
                        </li>
                        <li>
                            <a href='/blog-detail' className='pt-2'>
                                <Image src='https://wp.ditsolution.net/royella-multipurpose/wp-content/uploads/2024/03/blog-4-img2-80x80.jpg' />
                                <span className='text-ellipsis-2line pt-1'>How Booking our Hotel ResortUsing Websites?</span>
                            </a>
                            <div className='text-ellipsis-2line'>March 19, 2024</div>
                        </li>
                        <li>
                            <a href='/blog-detail' className='pt-2'>
                                <Image src='https://wp.ditsolution.net/royella-multipurpose/wp-content/uploads/2024/03/blog-4-img2-80x80.jpg' />
                                <span className='text-ellipsis-2line pt-1'>How Booking our Hotel ResortUsing Websites?</span>
                            </a>
                            <div className='text-ellipsis-2line'>March 19, 2024</div>
                        </li>
                        <li>
                            <a href='/blog-detail' className='pt-2'>
                                <Image src='https://wp.ditsolution.net/royella-multipurpose/wp-content/uploads/2024/03/blog-4-img2-80x80.jpg' />
                                <span className='text-ellipsis-2line pt-1'>How Booking our Hotel ResortUsing Websites?</span>
                            </a>
                            <div className='text-ellipsis-2line'>March 19, 2024</div>
                        </li>
                        <li>
                            <a href='/blog-detail' className='pt-2'>
                                <Image src='https://wp.ditsolution.net/royella-multipurpose/wp-content/uploads/2024/03/blog-4-img2-80x80.jpg' />
                                <span className='text-ellipsis-2line pt-1'>How Booking our Hotel ResortUsing Websites?</span>
                            </a>
                            <div className='text-ellipsis-2line'>March 19, 2024</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='widget'>
                <h5 className='widget-title mb-3'>{t('LABEL_CATEGORY')}</h5>
                <ul>
                    <li className='category'>
                        <a href='#'>City Guide</a> (5)
                    </li>
                    <li className='category'>
                        <a href='#'>Digital Nomad</a> (1)
                    </li>
                    <li className='category'>
                        <a href='#'>INTERIOR</a> (4)
                    </li>
                    <li className='category'>
                        <a href='#'>New Places</a> (2)
                    </li>
                    <li className='category'>
                        <a href='#'>Tips &amp; Tricks</a> (2)
                    </li>
                </ul>
            </div>
            <div className='widget'>
                <h5 className='widget-title mb-3'>{t('LABEL_TAGS')}</h5>
                <div className='btn-list'>
                    <Button className='btn-tag mx-1' variant='default'>
                        Accommodation
                    </Button>
                    <Button className='btn-tag mx-1' variant='default'>
                        Spa
                    </Button>
                    <Button className='btn-tag mx-1' variant='default'>
                        Restaurant
                    </Button>
                    <Button className='btn-tag mx-1' variant='default'>
                        Event
                    </Button>
                    <Button className='btn-tag mx-1' variant='default'>
                        Services
                    </Button>
                    <Button className='btn-tag mx-1' variant='default'>
                        Room
                    </Button>
                </div>
            </div>
        </div>
    );
}
